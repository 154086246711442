import { combineReducers } from "redux";

import Auth from "./auth/slice";
import Car from "./car/slice";
import Warehouse from "./warehouse/slice";
import Phase from "./phase/slice";
import Plane from "./plane/slice";
import Package from "./listPackage/slice";
import Permission from "./permission/slice";
import Transit from "./transit/slice";
import Shipment from "./shipment/slice";
import Container from "./container/slice";
import Report from "./report/slice";
import Customer from "./customer/slice";
import Dashboard from "./dashboard/slice";
import Notification from "./notification/slice";

const rootReducer = combineReducers({
  Auth,
  Car,
  Warehouse,
  Phase,
  Plane,
  Package,
  Permission,
  Transit,
  Shipment,
  Container,
  Report,
  Customer,
  Dashboard,
  Notification,
});

export default rootReducer;
