import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyARXR-wVRfnuN0BHyTwK5ePj_G4jEpp_rU",
  authDomain: "logistic-9ff2d.firebaseapp.com",
  projectId: "logistic-9ff2d",
  storageBucket: "logistic-9ff2d.firebasestorage.app",
  messagingSenderId: "492366870330",
  appId: "1:492366870330:web:478cfdfd7b80ab8f060203",
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async (setTokenFound: any) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BLMkmaxAKZZJRM1SY48pAImHA32hJ1dYJ0L5PLzmpfwIQn2QxnFXgGyebXGoagbJ45RZvhF1XOvwhHBTNJ2y2NA",
    });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (err) {
    setTokenFound(false);
  }
};
