import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getShipmentByPhaseDataSuccess,
  getShipmentByPhaseDataFail,
  getListScanSuccess,
  getListScanFail,
  exportPrintListSuccess,
  exportPrintListFail,
} from "./slice";

import {
  getShipmentByPhase,
  getListScan,
  exportPrintList,
} from "../../api/backend_helper";

function* fetchShipmentByPhaseById(
  action: PayloadAction<{ tripId: number }>
): Generator<any, void, any> {
  try {
    const { tripId } = action.payload;
    const response = yield call(getShipmentByPhase, tripId);
    yield put(getShipmentByPhaseDataSuccess(response));
  } catch (error) {
    yield put(getShipmentByPhaseDataFail(error));
  }
}

function* fetchListScanData(
  action: PayloadAction<{ shipment_id: number; s_global: string; query: {} }>
): Generator<any, void, any> {
  try {
    const { shipment_id, s_global, query } = action.payload;
    const response = yield call(getListScan, shipment_id, s_global, query);
    yield put(getListScanSuccess(response));
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      yield put(getListScanFail(error));
    } else {
      yield put(getListScanFail(error));
    }
  }
}

function* exportPrintListSaga(
  action: PayloadAction<{ shipmentId: number }>
): Generator<any, void, any> {
  try {
    const data = yield call(exportPrintList, action.payload.shipmentId);
    yield put(exportPrintListSuccess(data));
  } catch (error) {
    yield put(exportPrintListFail(error));
  }
}

function* ShipmentSaga(): Generator<any, void, any> {
  yield takeLatest("shipment/getShipmentByPhaseData", fetchShipmentByPhaseById);
  yield takeLatest("shipment/getListScanData", fetchListScanData);
  yield takeLatest("shipment/exportPrintList", exportPrintListSaga);
}

export default ShipmentSaga;
