import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../../assets/scss/theme-base/table.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ActionMenu from "../../components/ActionMenu";
import { Paginator } from "primereact/paginator";
import ModelDelete from "../../components/ModalDelete";
import { Toast } from "primereact/toast";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import LoadingSpinner from "../../components/LoadingSpinner";
import { InputIcon } from "primereact/inputicon";
import ModelAddCustomer from "./ModalAddCustomer";
import {
  getCustomerData,
  deleteCustomer,
  setCustomerDataNull,
} from "../../store/customer/slice";
import ModalEditCustomer from "./ModalEditCustomer";
import { IconEmailCustom } from "../../components/Icon";

function Customer() {
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(20);
  const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>(null);
  const [modalEdit, setModalEdit] = useState<boolean>(false);
  const [searchAddressSpx, setSearchAddressSpx] = useState<string | null>(null);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const [searchGlobal, setSearchGlobal] = useState<string>("");

  const {
    customerData,
    dataUserInfo,
    insertCustomerData,
    insertCustomerError,
    updateCustomerData,
    updateCustomerError,
    deleteCustomerData,
    deleteCustomerError,
  } = useSelector(
    (state: any) => ({
      customerData: state.Customer.customerData,
      insertCustomerData: state.Customer.insertCustomerData,
      insertCustomerError: state.Customer.insertCustomerError,
      updateCustomerData: state.Customer.updateCustomerData,
      updateCustomerError: state.Customer.updateCustomerError,
      deleteCustomerData: state.Customer.deleteCustomerData,
      deleteCustomerError: state.Customer.deleteCustomerError,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const rowId = rowData.id;
      if (rowId !== undefined) {
        dispatch(deleteCustomer({ id: rowId }));
      }
      setModalDelete(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const query: { [key: string]: string } = {};

      if (searchGlobal) query.s_global = searchGlobal;
      if (searchAddressSpx) query.s_address = searchAddressSpx;

      if (Object.keys(query).length > 0) {
        dispatch(
          getCustomerData({
            page: (first + rows) / rows,
            limit: rows,
            query: query,
          })
        );
      } else {
        dispatch(getCustomerData({ page: (first + rows) / rows, limit: rows }));
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [dispatch, rows, first, searchAddressSpx, searchGlobal]);

  useEffect(() => {
    if (
      insertCustomerData ||
      insertCustomerError ||
      updateCustomerData ||
      updateCustomerError ||
      deleteCustomerData ||
      deleteCustomerError
    ) {
      dispatch(getCustomerData({ page: (first + rows) / rows, limit: rows }));
    }
  }, [
    dispatch,
    rows,
    first,
    insertCustomerData,
    insertCustomerError,
    updateCustomerData,
    updateCustomerError,
    deleteCustomerData,
    deleteCustomerError,
  ]);

  //sửa dữ liệu
  const handleEdit = (rowData: any) => {
    if (selectArrayPermisstion?.includes("UPDATE_CUSTOMER")) {
      setModalEdit(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  //xóa dữ liệu
  const handleDelete = (rowData: any) => {
    if (selectArrayPermisstion?.includes("DELETE_CUSTOMER")) {
      setModalDelete(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  //Menu hành động
  const actionBodyTeamplate = (rowData: any) => {
    return (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
        type="branch"
      />
    );
  };

  useEffect(() => {
    if (deleteCustomerData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `${deleteCustomerData.message}`,
        life: 3000,
      });
      dispatch(setCustomerDataNull());
    }
    if (deleteCustomerError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${deleteCustomerError.message}`,
        life: 3000,
      });
      dispatch(setCustomerDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCustomerData, deleteCustomerError]);

  const onDeleteAllSearch = () => {
    setSearchGlobal("");
    setSearchAddressSpx("");
  };

  const statusCustomerNameTemplate = (rowData: any) => {
    return (
      <div className="flex flex-row align-items-center py-2 gap-2">
        {IconEmailCustom}
        <div>{rowData?.name}</div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex gap-3 justify-content-between w-full">
        <div className="flex w-9">
          <div className="mr-3">
            <Button
              label="Thêm mới"
              onClick={() => {
                setAddDialogVisible(true);
              }}
              style={{ height: "35px" }}
              disabled={!selectArrayPermisstion?.includes("CREATE_CUSTOMER")}
            />
          </div>
          <div className="flex w-9">
            <div className="w-10 mr-3">
              <IconField
                iconPosition="left"
                className="search-item flex items-center w-12"
              >
                <InputIcon className="pi pi-search"></InputIcon>
                <InputText
                  placeholder="Tìm kiếm"
                  className="border-1"
                  style={{
                    height: "35px",
                  }}
                  value={searchGlobal}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchGlobal(e.target.value)
                  }
                />
              </IconField>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 p-3 mt-3">
        {customerData && customerData.data ? (
          <>
            <DataTable
              value={customerData?.data}
              emptyMessage="Không có dữ liệu"
              className="custom-table"
              scrollable
              scrollHeight="calc(100vh - 16rem)"
              style={{ height: "calc(100vh - 16rem)" }}
            >
              <Column
                header="STT"
                body={(rowData, options) => (
                  <div className="text-center">
                    {first + options.rowIndex + 1}
                  </div>
                )}
                style={{ width: "5%" }}
                alignHeader={"center"}
                align={"center"}
              />
              <Column
                header="Tên khách hàng"
                field="name"
                body={statusCustomerNameTemplate}
                style={{ minWidth: "150px" }}
              />
              <Column
                header="Số điện thoại"
                field="phone_number"
                style={{ minWidth: "80px" }}
              />
              <Column
                header="Email"
                style={{ minWidth: "120px" }}
                field="email"
              />
              <Column
                header="Địa chỉ"
                style={{ minWidth: "120px" }}
                field="address"
              />
              <Column
                header="Ghi chú"
                field="description"
                style={{ minWidth: "120px" }}
              />
              <Column body={actionBodyTeamplate} style={{ width: "5%" }} />
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={customerData?.total}
              rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa khách hàng này không?
          </div>
        }
      />
      <ModelAddCustomer
        visible={addDialogVisible}
        onHide={() => setAddDialogVisible(false)}
        toast={toast}
      />
      <ModalEditCustomer
        visible={modalEdit}
        onHide={() => setModalEdit(false)}
        rowData={rowData}
        toast={toast}
      />
    </div>
  );
}

export default Customer;
