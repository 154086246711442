import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getNotificationSuccess,
  getNotificationFail,
  updateOnlyScreenSuccess,
  updateOnlyScreenFail,
  updateAllScreenNotificationSuccess,
  updateAllScreenNotificationFail,
} from "./slice";

import {
  getAllNotification,
  updateOnlyScreen,
  updateAllScreen,
} from "../../api/backend_helper";

function* fetchNotificationAll(
  action: PayloadAction<{ page: number; limit: number; query: {} }>
): Generator<any, void, any> {
  try {
    const { page, limit, query } = action.payload;
    const response = yield call(getAllNotification, page, limit, query);
    yield put(getNotificationSuccess(response));
  } catch (error) {
    yield put(getNotificationFail(error));
  }
}

function* updateOnlyNotificationSaga(
  action: PayloadAction<{ id: number; data: {} }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateOnlyScreen, id, data);
    yield put(updateOnlyScreenSuccess(response));
  } catch (error) {
    yield put(updateOnlyScreenFail(error));
  }
}

function* updateAllNotificationSaga(
  action: PayloadAction<{ data: {} }>
): Generator<any, void, any> {
  try {
    const { data } = action.payload;
    const response = yield call(updateAllScreen, data);
    yield put(updateAllScreenNotificationSuccess(response));
  } catch (error) {
    yield put(updateAllScreenNotificationFail(error));
  }
}

function* NotificationSaga(): Generator<any, void, any> {
  yield takeLatest("notification/getNotification", fetchNotificationAll);
  yield takeLatest(
    "notification/updateOnlyNotification",
    updateOnlyNotificationSaga
  );
  yield takeLatest(
    "notification/updateAllScreenNotification",
    updateAllNotificationSaga
  );
}

export default NotificationSaga;
