import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
const ActionMenu = ({
  onEdit,
  onDelete,
  onResetPassword,
  type,
}: {
  onEdit?: () => void;
  onDelete: () => void;
  onResetPassword?: () => void;

  type: string;
}) => {
  const menuAction = useRef<Menu>(null);

  let viewDetail = false;
  const actions = [];

  if (type === "debt") {
    actions.push({
      label: "Thêm vào nợ xấu",
      icon: "pi pi-dollar",
    });
  }

  if (type === "reset-password") {
    actions.push({
      label: "Đặt lại mật khẩu",
      icon: "pi pi-refresh",
      command: onResetPassword,
    });
  }

  if (onEdit) {
    actions.push({
      label: "Chỉnh sửa",
      icon: "pi pi-pen-to-square",
      command: onEdit,
    });
  }

  if (onDelete) {
    actions.push({
      label: "Xóa",
      icon: <i className="pi pi-trash" style={{ color: "red", marginRight: "8px" }} />,
      command: onDelete,
    });
  }

  return (
    <div className="flex justify-content-end align-items-center">
      <Menu model={actions} popup ref={menuAction} className="action-menu" />
      <Button icon="pi pi-ellipsis-v" onClick={(e) => menuAction.current?.toggle(e)} className="p-button-text" />
    </div>
  );
};

export default ActionMenu;
