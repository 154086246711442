import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getPackageDashBoardSuccess,
  getPackageDashBoardFail,
  getSearchDashBoardSuccess,
  getSearchDashBoardFail,
} from "./slice";

import {
  getDataDashBoardByCar,
  getPackageDashBoard,
} from "../../api/backend_helper";

function* fetchPackageDashBoardDataAll(
  action: PayloadAction<{ start_date: string; end_date: string; query: {} }>
): Generator<any, void, any> {
  try {
    const { start_date, end_date, query } = action.payload;
    const response = yield call(
      getPackageDashBoard,
      start_date,
      end_date,
      query
    );
    yield put(getPackageDashBoardSuccess(response));
  } catch (error: any) {
    yield put(getPackageDashBoardFail(error));
  }
}

function* fetchSearchDashBoardDataAll(
  action: PayloadAction<{ start_date: string; end_date: string; query: {} }>
): Generator<any, void, any> {
  try {
    const { start_date, end_date, query } = action.payload;
    const response = yield call(
      getDataDashBoardByCar,
      start_date,
      end_date,
      query
    );
    yield put(getSearchDashBoardSuccess(response));
  } catch (error: any) {
    yield put(getSearchDashBoardFail(error));
  }
}

function* DashboardSaga(): Generator<any, void, any> {
  yield takeLatest(
    "dashboard/getPackageDashBoard",
    fetchPackageDashBoardDataAll
  );
  yield takeLatest("dashboard/getSearchDashBoard", fetchSearchDashBoardDataAll);
}

export default DashboardSaga;
