import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DashBoardState {
  packageDashboardData: any | null;
  packageDashboardError: string | null;
  searchDashboardData: any | null;
  searchDashboardError: string | null;
  loading: boolean;
}

const initialState: DashBoardState = {
  packageDashboardData: null,
  packageDashboardError: null,
  searchDashboardData: null,
  searchDashboardError: null,
  loading: false,
};

const dashboarSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getPackageDashBoard: (
      state,
      action: PayloadAction<{
        start_date: string;
        end_date: string;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getPackageDashBoardSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.packageDashboardData = action.payload;
      state.packageDashboardError = null;
    },
    getPackageDashBoardFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.packageDashboardData = null;
      state.packageDashboardError = action.payload;
    },
    getSearchDashBoard: (
      state,
      action: PayloadAction<{
        start_date: string;
        end_date: string;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getSearchDashBoardSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.searchDashboardData = action.payload;
      state.searchDashboardError = null;
    },
    getSearchDashBoardFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.searchDashboardData = null;
      state.searchDashboardError = action.payload;
    },
    setDashBoardDataNull: (state) => {
      state.loading = false;
      state.packageDashboardError = null;
      state.searchDashboardError = null;
    },
  },
});

export const {
  getPackageDashBoard,
  getPackageDashBoardSuccess,
  getPackageDashBoardFail,
  getSearchDashBoard,
  getSearchDashBoardSuccess,
  getSearchDashBoardFail,
  setDashBoardDataNull,
} = dashboarSlice.actions;

export default dashboarSlice.reducer;
