import React, { useRef, useState, useContext, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { Badge } from "primereact/badge";
import { DataContext } from "../../contexts/data/DataProvider";
import { getDataInfo, logout } from "../../store/auth/slice";
import { site } from "../../api/url_helper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  updateAllScreenNotification,
  updateOnlyNotification,
} from "../../store/notification/slice";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";

// Hàm chuyển đổi định dạng ngày giờ
function formatDateTime(dateTimeStr: string) {
  // Chuyển chuỗi thành một đối tượng Date
  const date = new Date(dateTimeStr.replace(" ", "T"));

  // Kiểm tra nếu không hợp lệ
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format");
  }

  // Định dạng ngày, tháng, năm và giờ theo định dạng yêu cầu
  const formattedDate = `${date.toTimeString().slice(0, 8)} ${String(
    date.getDate()
  ).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}/${date.getFullYear()}`;

  return formattedDate;
}

function Header() {
  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
  const accountMenu = useRef<any>(null);
  const [first, setFirst] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listNotificationData, setListNotificationData] = useState<any[]>([]);
  const op = useRef<any>(null);
  const { notification } = useContext(DataContext);

  const {
    uploadFileData,
    updateProfileData,
    dataUserInfo,
    updateUserData,
    deleteUserData,
    notificationData,
    notificationAllSeen,
    notificationOnlySeen,
  } = useSelector(
    (state: any) => ({
      uploadFileData: state.Auth.uploadFileData,
      updateProfileData: state.Auth.updateProfileData,
      dataUserInfo: state.Auth.dataUserInfo,
      updateUserData: state.Auth.updateUserData,
      deleteUserData: state.Auth.deleteUserData,
      notificationData: state.Notification.notificationData,
      notificationAllSeen: state.Notification.notificationAllSeen,
      notificationOnlySeen: state.Notification.notificationOnlySeen,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDataInfo());
  }, [
    dispatch,
    uploadFileData,
    updateProfileData,
    updateUserData,
    deleteUserData,
  ]);

  useEffect(() => {
    dispatch(getNotification({ page: first, limit: rows }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    first,
    rows,
    notificationAllSeen,
    notificationOnlySeen,
    notification,
  ]);

  useEffect(() => {
    if (notificationData && Array.isArray(notificationData.data)) {
      setListNotificationData((prevNotifications) => {
        const newNotifications = notificationData.data.filter(
          (newNotification: any) =>
            !prevNotifications.some(
              (existingNotification) =>
                existingNotification.id === newNotification.id
            )
        );
        return [...prevNotifications, ...newNotifications];
      });
    }
  }, [notificationData]);

  const { titlePage } = useContext(DataContext);

  const profileMenuItems = [
    {
      label: "Tài khoản",
      items: [
        {
          label: "Thông tin tài khoản",
          icon: "pi pi-user",
          command: () => {
            navigate("/profile");
          },
        },
        {
          label: "Thay đổi mật khẩu",
          icon: "pi pi-key",
          command: () => {
            navigate("/change-password");
          },
        },
        {
          label: "Đăng xuất",
          icon: "pi pi-sign-out",
          command: () => {
            dispatch(logout());
            navigate("/login");
            localStorage.removeItem("changeAvatar");
          },
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex align-items-center justify-content-between bg-white h-4rem px-2 md:px-4">
        <div className="flex flex-row align-items-center">
          <div onClick={() => setMobileMenuVisible(true)}>
            <i
              className="pi pi-bars p-3 cursor-pointer xl:hidden"
              style={{ fontSize: "1.5rem" }}
            ></i>
          </div>
          <h3>{titlePage}</h3>
        </div>
        <div className="flex flex-row align-items-center">
          <div className="p-3 cursor-pointer">
            <OverlayPanel ref={op} className="w-3">
              <div className="flex justify-content-between mb-2">
                <h3>Thông báo</h3>
                <h4
                  style={{ cursor: "pointer" }}
                  className="font-medium"
                  onClick={() => {
                    dispatch(updateAllScreenNotification({}));
                    const updatedList = listNotificationData.map((item) => ({
                      ...item,
                      is_seen: true,
                    }));
                    setListNotificationData(updatedList);
                  }}
                >
                  Đánh dấu tất cả đã đọc
                </h4>
              </div>
              <div style={{ maxHeight: "75vh", overflow: "auto" }}>
                {listNotificationData.map((item: any) => (
                  <div
                    key={item.id}
                    className={`notification-item ${
                      item.is_seen ? "unseen" : "seen"
                    }`}
                    onClick={() => {
                      dispatch(updateOnlyNotification({ id: item.id }));
                      const updatedList = listNotificationData.map(
                        (notification) =>
                          notification.id === item.id
                            ? { ...notification, is_seen: true }
                            : notification
                      );
                      setListNotificationData(updatedList);
                    }}
                  >
                    <i className="pi pi-bell mt-1"></i>
                    <p key={item.id} className="ml-3">
                      <b className="mb-1">{item.title}</b>
                      <br />
                      <span>{item.content}</span>
                      <br />
                      <span style={{ fontSize: "11px" }}>
                        {formatDateTime(item.send_at)}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
              <div className="flex justify-content-center mt-3">
                <Button
                  type="submit"
                  label="Tải thêm thông báo"
                  size="small"
                  className="custom-button w-full"
                  onClick={() => {
                    setFirst((prevFirst) => prevFirst + 1);
                  }}
                />
              </div>
            </OverlayPanel>
            <i
              className="pi pi-bell p-overlay-badge"
              style={{ fontSize: "1.5rem" }}
              onClick={(e: any) => {
                op?.current?.toggle(e);
              }}
            >
              {notificationData?.totalNotSeen > 0 && (
                <Badge
                  value={notificationData?.totalNotSeen}
                  severity="danger"
                ></Badge>
              )}
            </i>
          </div>
          <div
            className="flex align-items-center cursor-pointer p-3 gap-2 border-round text-700"
            onClick={(event) => accountMenu.current.toggle(event)}
          >
            <Menu
              model={profileMenuItems}
              popup
              ref={accountMenu}
              className="w-auto"
              pt={{ menuitem: { className: "mx-2" } }}
            />
            <Avatar
              image={
                dataUserInfo?.data?.avatar
                  ? `${site}${dataUserInfo?.data?.avatar}`
                  : "/images/avatas/avatar-null.png"
              }
              shape="circle"
            />
            <div className="flex flex-column">
              <span className="font-bold">{dataUserInfo?.data?.full_name}</span>
              <span className="font-normal">
                {dataUserInfo?.data?.username}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* SidebarMenu Mobile */}
      <div className="card flex justify-content-center">
        <Sidebar
          header={
            <Image src="images/logo/unnamed.png" alt="Image" width="100" />
          }
          visible={mobileMenuVisible}
          onHide={() => setMobileMenuVisible(false)}
        >
          <SidebarMenu
            mobile={mobileMenuVisible}
            setMobileMenuVisible={setMobileMenuVisible}
          />
        </Sidebar>
      </div>
    </>
  );
}
export default Header;
