import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getPackageReportDataSuccess,
  getPackageReportDataFail,
  exportExcelPackageSuccess,
  exportExcelPackageFail,
} from "./slice";

import {
  getPackageReport,
  exportPackageReport,
} from "../../api/backend_helper";

function* fetchPackAgeReportDataAll(
  action: PayloadAction<{ page: number; limit: number; query: {} }>
): Generator<any, void, any> {
  try {
    const { page, limit, query } = action.payload;
    const response = yield call(getPackageReport, page, limit, query);
    yield put(getPackageReportDataSuccess(response));
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      yield put(getPackageReportDataFail(error?.response));
    } else {
      yield put(getPackageReportDataFail(error));
    }
  }
}

function* exportExcelReportData(
  action: PayloadAction<{ page: number; limit: number; query: {} }>
): Generator<any, void, any> {
  try {
    const { page, limit, query } = action.payload;
    const response = yield call(exportPackageReport, page, limit, query);
    yield put(exportExcelPackageSuccess(response));
  } catch (error: any) {
    yield put(exportExcelPackageFail(error));
  }
}

function* ReportSaga(): Generator<any, void, any> {
  yield takeLatest("report/getPackageReportData", fetchPackAgeReportDataAll);
  yield takeLatest("report/exportExcelPackage", exportExcelReportData);
}

export default ReportSaga;
