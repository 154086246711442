import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ReportState {
  packageReportData: any | null;
  error: string | null;
  loading: boolean;
  exportExcelData: any | null;
  exportExcelError: any | null;
}

const initialState: ReportState = {
  packageReportData: null,
  error: null,
  loading: false,
  exportExcelData: null,
  exportExcelError: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    getPackageReportData: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getPackageReportDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.packageReportData = action.payload;
      state.error = null;
    },
    getPackageReportDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.packageReportData = null;
      state.error = action.payload;
    },
    exportExcelPackage: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    exportExcelPackageSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportExcelData = action.payload;
      state.exportExcelError = null;
    },
    exportExcelPackageFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportExcelError = action.payload;
      state.exportExcelData = null;
    },
    setReportDataNull: (state) => {
      state.loading = false;
      state.error = null;
      state.exportExcelData = null;
      state.exportExcelError = null;
    },
  },
});

export const {
  getPackageReportData,
  getPackageReportDataSuccess,
  getPackageReportDataFail,
  exportExcelPackage,
  exportExcelPackageSuccess,
  exportExcelPackageFail,
  setReportDataNull,
} = reportSlice.actions;

export default reportSlice.reducer;
