import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { site } from "../api/url_helper";
import { shallowEqual, useSelector } from "react-redux";
import {
  hasCommonPermissionsCar,
  hasCommonPermissionsPackage,
  hasCommonPermissionsPermission,
  hasCommonPermissionsPlane,
  hasCommonPermissionsReport,
  hasCommonPermissionsShipment,
  hasCommonPermissionsUser,
  hasCommonPermissionsWarehouse,
} from "../ultis/permissionUtils";

const Authmiddleware = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { dataUserInfo } = useSelector(
    (state: any) => ({
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const getAuth = async () => {
      try {
        const data = await axios.get(`${site}/api/v1/auth/get-auth`, {
          withCredentials: true,
        });
        if (data.status === 200) {
          const auth = data.data.auth;
          if (auth && dataUserInfo) {
            switch (pathname) {
              // case "/":
              //   if (
              //     !hasCommonPermissionsReport(
              //       dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
              //     )
              //   ) {
              //     navigate("/blankpage", { replace: true });
              //   }
              //   break;
              case "/report":
                if (!hasCommonPermissionsReport(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/package":
                if (!hasCommonPermissionsPackage(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/container":
                if (!hasCommonPermissionsCar(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/transit":
                if (!hasCommonPermissionsShipment(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/warehouse":
                if (!hasCommonPermissionsWarehouse(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/car":
                if (!hasCommonPermissionsCar(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/plane":
                if (!hasCommonPermissionsPlane(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/user":
                if (!hasCommonPermissionsUser(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              case "/permission":
                if (!hasCommonPermissionsPermission(dataUserInfo?.data?.permissions_data.map((e: any) => e.name))) {
                  navigate("/blankpage", { replace: true });
                }
                break;
              // default:
              //   navigate("/login", { replace: true });
              //   break;
            }
          }
        }
      } catch (error) {
        navigate("/login");
      } finally {
        setIsChecking(false);
      }
    };
    getAuth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dataUserInfo]);

  if (isChecking) {
    return <div></div>;
  }

  return <>{props.children}</>;
};

export default Authmiddleware;
