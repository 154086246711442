import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { getPermissionGroupData } from "../../store/permission/slice";
import { setUserDataNull, updateUser } from "../../store/auth/slice";
import "../../assets/scss/theme-base/table.scss";
import { Calendar } from "primereact/calendar";
import { formatDateUser } from "../../components/utils";
import { site } from "../../api/url_helper";
import { Avatar } from "primereact/avatar";
import { InputSwitch } from "primereact/inputswitch";

interface Gender {
  name: string;
  code: string;
}

function convertToLocalDateString(dateString: any) {
  const date = new Date(dateString);
  return date;
}
export default function ModalEditUser({
  visible,
  onHide,
  rowData,
  toast,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
  toast: any;
}) {
  const dispatch = useDispatch();
  const genderData: Gender[] = [
    { name: "Nam", code: "MALE" },
    { name: "Nữ", code: "FEMALE" },
  ];

  const { permissionGroupData, updateUserData, updateUserError, dataUserInfo } =
    useSelector(
      (state: any) => ({
        permissionGroupData: state.Permission.permissionGroupData,
        updateUserData: state.Auth.updateUserData,
        updateUserError: state.Auth.updateUserError,
        dataUserInfo: state.Auth.dataUserInfo,
      }),
      shallowEqual
    );
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [initialUrlAvatar, setInitialUrlAvatar] = useState<string | undefined>(
    undefined
  );
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  useEffect(() => {
    dispatch(getPermissionGroupData());
  }, [dispatch, updateUserData]);

  useEffect(() => {
    if (rowData) {
      setInitialUrlAvatar(rowData?.avatar);
      setChecked(rowData?.active);
    }
  }, [rowData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: rowData?.id,
      full_name: rowData ? rowData.full_name : "",
      email: rowData && rowData.email !== "null" ? rowData.email : "",
      username: rowData ? rowData.username : "",
      phone_number: rowData ? rowData.phone_number : "",
      position: rowData ? rowData.position : "",
      password: "******",
      confirm_password: "******",
      address: rowData && rowData.address !== "null" ? rowData.address : "",
      gender: rowData ? rowData.gender : "",
      dob: rowData ? convertToLocalDateString(rowData?.dob) : "",
      permissions_group_id: rowData ? rowData.permissions_group_id : "",
      file: null,
      identification_code: rowData ? rowData.identification_code : "",
    },
    validationSchema: Yup.object().shape({
      full_name: Yup.string().required("Vui lòng nhập họ và tên"),
      username: Yup.string().required("Vui lòng nhập tên đăng nhập"),
      password: Yup.string().required("Vui lòng nhập mật khẩu"),
      permissions_group_id: Yup.string().required("Vui lòng chọn nhóm quyền"),
      gender: Yup.string().required("Vui lòng chọn giới tính"),
      dob: Yup.string().required("Vui lòng chọn ngày sinh"),
      phone_number: Yup.string()
        .required("Vui lòng nhập số điện thoại")
        .matches(/^\d+$/, "Số điện thoại phải là một số")
        .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
        .max(11, "Số điện thoại chỉ được phép có tối đa 11 chữ số"),
      identification_code: Yup.string().required("Vui lòng nhập CCCD"),
    }),
    onSubmit: (values: any) => {
      const formData = {
        ...values,
        file: uploadedImage || null,
        active: checked,
        dob: formatDateUser(values.dob),
      };
      dispatch(updateUser(formData));
    },
  });

  useEffect(() => {
    if (updateUserData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: updateUserData?.message,
        life: 3000,
      });
      onHide();
      dispatch(setUserDataNull());
      setImageUrl("");
    }
    if (updateUserError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: updateUserError?.response?.data?.message,
        life: 3000,
      });
      dispatch(setUserDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserData, updateUserError, dispatch]);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Chỉnh sửa người dùng</span>
    </div>
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedImage(file);
    }
  };

  useEffect(() => {
    if (uploadedImage) {
      const url = URL.createObjectURL(uploadedImage);
      setImageUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setImageUrl(undefined);
    }
  }, [uploadedImage]);

  return (
    <div className="card w-12 md:w-6 lg:w-3">
      <Dialog
        visible={visible}
        onHide={() => {
          onHide();
          setUploadedImage(null);
          formik.resetForm();
          setImageUrl("");
        }}
        header={headerElement}
        className="w-10 md:w-5"
      >
        <div className="card bg-white p-3 border-round-xl">
          <div className="flex mb-2 justify-content-center">
            <div className="image-upload-container flex flex-column align-items-center w-4">
              <Avatar
                className="p-overlay-badge flex justify-content-center"
                image={
                  imageUrl
                    ? imageUrl
                    : initialUrlAvatar
                    ? `${site}${initialUrlAvatar}`
                    : "/images/avatas/avatar-null.png"
                }
                shape="circle"
                style={{ width: "120px", height: "120px" }}
              />
              <div className="button-container w-full text-start mt-2 flex justify-content-center">
                <input
                  type="file"
                  name="files"
                  id="upload"
                  multiple
                  hidden
                  accept="image/jpeg, image/png, image/gif"
                  onChange={handleFileChange}
                />
                <label htmlFor="upload" className="upload-file">
                  <i
                    className="pi pi-camera"
                    style={{
                      fontSize: "13px",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  ></i>
                  Tải ảnh
                </label>
              </div>
            </div>
          </div>
          <div className="flex gap-2 ">
            <div className="flex flex-column gap-1 flex-1 mb-3 ">
              <label htmlFor="full_name" className="mb-2">
                Họ & tên <span style={{ color: "red" }}>*</span>
              </label>
              <InputText
                value={formik.values.full_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="full_name"
                name="full_name"
                autoComplete="on"
                type="text"
                className="w-full"
                invalid={
                  formik.touched.full_name && formik.errors.full_name
                    ? true
                    : false
                }
              />
              {formik.touched.full_name && formik.errors.full_name ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.full_name)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label className="mb-2" htmlFor="gender">
                Giới tính <span style={{ color: "red" }}>*</span>
              </label>
              <Dropdown
                value={formik.values.gender}
                onChange={(e: DropdownChangeEvent) => {
                  formik.setFieldValue("gender", e.value);
                }}
                options={genderData}
                optionLabel="name"
                className="w-full"
                onBlur={formik.handleBlur}
                optionValue="code"
                placeholder="Chọn giới tính"
                invalid={
                  formik.touched.gender && formik.errors.gender ? true : false
                }
              />
              {formik.touched.gender && formik.errors.gender ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.gender)}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-column gap-1 flex-1 mb-3 ">
              <label htmlFor="dob" className="mb-2">
                Ngày sinh <span style={{ color: "red" }}>*</span>
              </label>
              <Calendar
                id="dob"
                value={formik.values.dob}
                onChange={(e) => formik.setFieldValue("dob", e.value)}
                locale="vi"
                className="w-full"
                dateFormat="dd/mm/yy"
                placeholder="Chọn ngày sinh"
                invalid={formik.touched.dob && formik.errors.dob ? true : false}
              />
              {formik.touched.dob && formik.errors.dob ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.dob)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label htmlFor="phone_number" className="mb-2">
                Số điện thoại <span style={{ color: "red" }}>*</span>
              </label>
              <InputText
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="phone_number"
                name="phone_number"
                autoComplete="on"
                type="text"
                className="w-full"
                invalid={
                  formik.touched.phone_number && formik.errors.phone_number
                    ? true
                    : false
                }
              />
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.phone_number)}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex mb-1 gap-2">
            <div className="flex flex-column gap-1 flex-1 mb-2 ">
              <label htmlFor="email" className="mb-2">
                Email
              </label>
              <InputText
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="email"
                name="email"
                autoComplete="on"
                type="text"
                className="w-full"
                invalid={
                  formik.touched.email && formik.errors.email ? true : false
                }
              />
              {formik.touched.email && formik.errors.email ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.email)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label htmlFor="address" className="mb-2">
                Địa chỉ
              </label>
              <InputText
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="address"
                name="address"
                autoComplete="on"
                type="text"
                className="w-full"
                invalid={
                  formik.touched.address && formik.errors.address ? true : false
                }
              />
              {formik.touched.address && formik.errors.address ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.address)}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-column gap-1 flex-1 mb-2">
              <label htmlFor="identification_code" className="mb-2">
                Căn cước công dân <span style={{ color: "red" }}>*</span>
              </label>
              <InputText
                value={formik.values.identification_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="identification_code"
                name="identification_code"
                autoComplete="on"
                type="text"
                className="w-full"
                invalid={
                  formik.touched.identification_code &&
                  formik.errors.identification_code
                    ? true
                    : false
                }
              />
              {formik.touched.identification_code &&
              formik.errors.identification_code ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.identification_code)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1"></div>
          </div>
          <div className="mt-4 mb-2">
            <div className="flex gap-2 mb-3">
              <label htmlFor="username" className="mb-2 pt-1 font-medium">
                Cho phép truy cập
              </label>
              <InputSwitch
                checked={checked}
                onChange={(e) => setChecked(e.value)}
              />
            </div>
            <div className="flex gap-2 mb-2">
              <div className="flex flex-column w-6">
                <label htmlFor="username" className="mb-2">
                  Tên đăng nhập <span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="username"
                  name="username"
                  autoComplete="on"
                  type="text"
                  className="w-full"
                  invalid={
                    formik.touched.username && formik.errors.username
                      ? true
                      : false
                  }
                />
                {formik.touched.username && formik.errors.username ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontSize: "0.9rem",
                    }}
                  >
                    {String(formik.errors.username)}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-column gap-1 flex-1 w-6">
                <label className="mb-1" htmlFor="password">
                  Mật khẩu <span style={{ color: "red" }}>*</span>
                </label>
                <Password
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="on"
                  disabled
                  inputClassName="w-full"
                  pt={{
                    iconField: {
                      root: { className: "w-full" },
                    },
                  }}
                  toggleMask
                  feedback={false}
                  invalid={
                    formik.touched.password && formik.errors.password
                      ? true
                      : false
                  }
                />
                {formik.touched.password && formik.errors.password ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontSize: "0.9rem",
                    }}
                  >
                    {String(formik.errors.password)}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-column gap-1 flex-1 mb-2">
                <label className="mb-2" htmlFor="permissions_group_id">
                  Chọn nhóm quyền <span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown
                  value={formik.values.permissions_group_id}
                  onChange={(e: DropdownChangeEvent) => {
                    formik.setFieldValue("permissions_group_id", e.value);
                  }}
                  options={permissionGroupData?.data || []}
                  optionLabel="name"
                  className="w-full custom-dropdown"
                  onBlur={formik.handleBlur}
                  optionValue="id"
                  placeholder="Chọn nhóm quyền"
                  emptyMessage={
                    !selectArrayPermisstion?.includes("VIEW_PERMISSION_GROUP")
                      ? "Bạn chưa được cấp quyền"
                      : ""
                  }
                  invalid={
                    formik.touched.permissions_group_id &&
                    formik.errors.permissions_group_id
                      ? true
                      : false
                  }
                />
                {formik.touched.permissions_group_id &&
                formik.errors.permissions_group_id ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontSize: "0.9rem",
                    }}
                  >
                    {String(formik.errors.permissions_group_id)}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-column gap-1 flex-1 mb-2">
                <label htmlFor="position" className="mb-2">
                  Chức vụ
                </label>
                <InputText
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="position"
                  name="position"
                  autoComplete="on"
                  type="text"
                  className="w-full"
                  invalid={
                    formik.touched.position && formik.errors.position
                      ? true
                      : false
                  }
                />
                {formik.touched.position && formik.errors.position ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontSize: "0.9rem",
                    }}
                  >
                    {String(formik.errors.position)}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex gap-2 justify-content-end">
            <Button
              label="Hủy"
              severity="secondary"
              size="small"
              onClick={() => {
                onHide();
                formik.resetForm();
                setImageUrl("");
              }}
              style={{ width: "80px" }}
            />
            <Button
              type="submit"
              label="Lưu"
              size="small"
              onClick={() => formik.handleSubmit()}
              style={{ width: "80px" }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
