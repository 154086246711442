import React from "react";
import { Tag } from "primereact/tag";

export const statusTemplate = (rowData: any) => {
  switch (rowData?.status) {
    case "DELIVERED":
      return (
        <Tag
          className="border-round-xl px-3 text-sm font-semibold"
          value="Đã giao hàng"
          severity="success"
          style={{
            width: "130px",
            backgroundColor: "rgba(46, 184, 76, 0.2)",
            color: "rgba(46, 184, 76, 1)",
          }}
        />
      );

    case "LATE_DELIVERY":
      return (
        <Tag
          className="border-round-xl px-3 text-sm font-semibold"
          value="Giao hàng trễ"
          severity="danger"
          style={{
            width: "130px",
            backgroundColor: "#FFD9D9",
            color: "red",
          }}
        />
      );
    case "IN_TRANSIT":
      return (
        <Tag
          className="border-round-xl text-sm font-semibold"
          value="Đang vận chuyển"
          severity="danger"
          style={{
            width: "130px",
            backgroundColor: "#E0DFFF",
            color: "blue",
          }}
        />
      );
    case "WAITING":
      return (
        <Tag
          className="border-round-xl text-sm font-semibold"
          value="Chờ vận chuyển"
          severity="danger"
          style={{
            width: "130px",
            color: "black",
            backgroundColor: "#EFEFEF",
          }}
        />
      );
    default:
      return rowData?.status;
  }
};
