import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../contexts/data/DataProvider";
import { Tooltip } from "primereact/tooltip";
import {
  IconReport,
  IconReportClick,
  IconPackage,
  IconPackageClick,
  IconTransit,
  IconTransitClick,
  IconWarehouse,
  IconWarehouseClick,
  IconCar,
  IconCarClick,
  IconPlane,
  IconPlaneClick,
  IconUser,
  IconUserClick,
  IconPermission,
  IconPermissionClick,
  IconStatistical,
  IconStatisticalClick,
  IconContainer,
  IconContainerClick,
  IconCustomer,
  IconCustomerClick,
  IconEmail,
  IconEmailClick,
} from "../../components/Icon";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

interface SidebarMenuProps {
  mobile?: boolean;
  setMobileMenuVisible?: (visible: boolean) => void;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  mobile,
  setMobileMenuVisible,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const { setTitlePage } = useContext(DataContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (setMobileMenuVisible) {
      setMobileMenuVisible(!mobile);
    }
  };

  const { dataUserInfo } = useSelector(
    (state: any) => ({
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );
  const permissions = dataUserInfo?.data?.permissions_data || [];
  // Báo cáo
  const reportPermissions = ["VIEW_REPORT", "VIEW_SHIPMENT"];
  // Quyền cho thao tác
  const operationPermissions = ["VIEW_PACKAGE", "VIEW_CAR", "VIEW_CONTAINER"];
  // Quyền cho danh mục
  const categoryPermissions = [
    "VIEW_WAREHOUSE",
    "VIEW_CAR",
    "VIEW_PLANE",
    "VIEW_USER",
    "VIEW_PERMISSION_GROUP",
    "VIEW_CUSTOMER",
  ];

  const isPermissionVisibleStatistical = reportPermissions.some(
    (permission: string) => permissions.some((e: any) => e.name === permission)
  );

  // thao tác
  const isPermissionVisibleOperation = operationPermissions.some(
    (permission: string) => permissions.some((e: any) => e.name === permission)
  );

  // Danh mục
  const isPermissionVisibleCategory = categoryPermissions.some((permission) =>
    permissions.some((e: any) => e.name === permission)
  );

  const itemRenderer = (item: any) => {
    if (!item.url) {
      return null;
    }
    const isSelected =
      location.pathname === item.url ||
      (location.pathname === "/detail-transit" && item.url === "/transit");

    return (
      <Link
        to={item.url}
        className={`flex align-items-center p-menuitem-content p-menuitem-link border-round-md mx-3 p-3 svg-hover ${
          isSelected ? " text-white bg-blue-700" : ""
        } ${isSidebarOpen ? "" : "justify-content-center"}`}
        data-pr-tooltip={item.label}
        data-pr-position="right"
        onClick={() => {
          localStorage.setItem("menu", JSON.stringify(item.label));
          handleClick();
        }}
      >
        <span
          className={`p-menuitem-icon ${
            isSidebarOpen ? "" : "menu-item-icon-only"
          }`}
        >
          {isSelected ? <span>{item.svgClick}</span> : <span>{item.svg}</span>}
        </span>
        {isSidebarOpen && (
          <span className="menu-item-text mr-3 white-space-nowrap ml-4">
            {item.label}
          </span>
        )}
      </Link>
    );
  };
  let items = [
    {
      template: () => {
        return <></>;
      },
    },
    ...(isPermissionVisibleStatistical === true
      ? [
          {
            label: t("Báo cáo"),
            items: [
              {
                label: "Thống kê",
                svg: IconStatistical,
                svgClick: IconStatisticalClick,
                url: "/",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_REPORT"
                ),
              },
              {
                label: "Báo cáo",
                svg: IconReport,
                svgClick: IconReportClick,
                url: "/report",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_REPORT"
                ),
              },
            ].filter((subItem) => subItem.visible),
          },
          {
            label: "Đang vận chuyển",
            svg: IconTransit,
            svgClick: IconTransitClick,
            url: "/transit",
            template: itemRenderer,
            visible: dataUserInfo?.data?.permissions_data.some(
              (e: any) => e.name === "VIEW_SHIPMENT"
            ),
          },
        ]
      : []),
    ...(isPermissionVisibleOperation === true
      ? [
          {
            label: "Thao tác",
            items: [
              {
                label: "Import dữ liệu",
                svg: IconPackage,
                svgClick: IconPackageClick,
                url: "/package",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_PACKAGE"
                ),
              },
              {
                label: "Quản lý POD",
                svg: IconContainer,
                svgClick: IconContainerClick,
                url: "/container",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_CONTAINER"
                ),
              },
            ],
          },
        ]
      : []),
    ...(isPermissionVisibleCategory === true
      ? [
          {
            label: "Danh mục",
            items: [
              {
                label: "Kho SPX",
                svg: IconWarehouse,
                svgClick: IconWarehouseClick,
                url: "/warehouse",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_WAREHOUSE"
                ),
              },
              {
                label: "Xe tải",
                svg: IconCar,
                svgClick: IconCarClick,
                url: "/car",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_CAR"
                ),
              },
              {
                label: "Chuyến bay",
                svg: IconPlane,
                svgClick: IconPlaneClick,
                url: "/plane",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_PLANE"
                ),
              },
              {
                label: "Danh sách Email",
                svg: IconEmail,
                svgClick: IconEmailClick,
                url: "/customer",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_CUSTOMER"
                ),
              },
              {
                label: "Người dùng",
                svg: IconUser,
                svgClick: IconUserClick,
                url: "/user",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_USER"
                ),
              },
              {
                label: "Phân quyền",
                svg: IconPermission,
                svgClick: IconPermissionClick,
                url: "/permission",
                template: itemRenderer,
                visible: dataUserInfo?.data?.permissions_data.some(
                  (e: any) => e.name === "VIEW_PERMISSION_GROUP"
                ),
              },
            ],
          },
        ]
      : []),
  ];

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setTitlePage("Thống kê");
        break;
      case "/report":
        setTitlePage("Báo cáo");
        break;
      case "/package":
        setTitlePage("Import dữ liệu");
        break;
      case "/container":
        setTitlePage("Quản lý POD");
        break;
      case "/transit":
        setTitlePage("Đang vận chuyển");
        break;
      case "/detail-transit":
        setTitlePage("Chi tiết xe đang vận chuyển");
        break;
      case "/warehouse":
        setTitlePage("Kho SPX");
        break;
      case "/car":
        setTitlePage("Xe tải");
        break;
      case "/plane":
        setTitlePage("Chuyến bay");
        break;
      case "/customer":
        setTitlePage("Danh sách Email");
        break;
      case "/user":
        setTitlePage("Người dùng");
        break;
      case "/permission":
        setTitlePage("Phân quyền");
        break;
      case "/profile":
        setTitlePage("Thông tin tài khoản");
        break;
      case "/change-password":
        setTitlePage("Thay đổi mật khẩu");
        break;
      default:
        setTitlePage("Thống kê");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div
      className={`sidebar flex flex-column h-screen md:border-right-1 border-gray-200 ${
        isSidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      {!isSidebarOpen && (
        <Tooltip target=".svg-hover" showDelay={300} hideDelay={500} />
      )}
      <button
        className={`toggle-button ${isSidebarOpen ? "btn-open" : "btn-closed"}`}
        onClick={() => {
          setIsSidebarOpen(!isSidebarOpen);
        }}
      >
        <i
          className={`pi ${
            isSidebarOpen
              ? "pi-chevron-left text-primary text-sm"
              : "pi-chevron-right text-primary text-sm"
          }`}
        ></i>
      </button>
      <div
        className={`justify-content-center p-3 logo-image cursor-pointer`}
        onClick={() => navigate("/dashboard")}
      >
        <Image src={"images/logo/unnamed.png"} alt="Image" width="60" />
      </div>
      <Menu
        model={items}
        className="w-full border-noround overflow-auto border-none"
      />
    </div>
  );
};

export default SidebarMenu;
