import { useEffect, useContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routes";
import { privateRoutes } from "./routes";
import PrivateLayout from "./layout/PrivateLayout";
import Authmiddleware from "./routes/authMiddleware";
import PublicLayout from "./layout/PublicLayout";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/themes/main/green/theme.scss";
import "../src/assets/scss/global.css";

import io from "socket.io-client";
import { site } from "./api/url_helper";
import { DataContext } from "./contexts/data/DataProvider";
import { requestForToken } from "./ultis/firebase";

function App() {
  const {
    message,
    setMessage,
    totalPackage,
    setTotalPackage,
    currentPackage,
    setCurrentPackage,
    setSocket,
    setUpdateUserCount,
    notification,
    setSttFile,
    setNotification,
  } = useContext(DataContext);

  const [tokenFound, setTokenFound] = useState(false);

  const id = localStorage.getItem("currentUserId");

  useEffect(() => {
    requestForToken(setTokenFound);
  }, []);

  useEffect(() => {
    const newSocket = io(site, {
      secure: true,
      rejectUnauthorized: false, // Chỉ cần đặt rejectUnauthorized là false nếu bạn sử dụng chứng chỉ tự ký
      path: "/socket.io",
      transports: ["websocket"], // Force WebSocket transport
    });

    if (id) {
      newSocket.emit("register", id);
    }

    setSocket(newSocket);
    newSocket.on("updateUserCount", (mes) => {
      setUpdateUserCount(mes);
    });

    newSocket.on("message", (mes) => {
      newSocket.emit("register", id);
      setMessage(mes);
    });

    newSocket.on("getTotalPackage", (pk) => {
      setTotalPackage(pk);
    });

    newSocket.on("getCurrentPackage", (pk) => {
      setCurrentPackage(pk);
    });

    newSocket.on("sttFile", (stt) => {
      setSttFile(stt);
    });

    newSocket.on("notification", (noti) => {
      setNotification(noti);
    });

    return () => {
      newSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, id]);

  return (
    <BrowserRouter>
      <div>
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PublicLayout>
                    <Page />
                  </PublicLayout>
                }
              />
            );
          })}
          {privateRoutes.map((route, index) => {
            const Page = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Authmiddleware path={route.path}>
                    <PrivateLayout>
                      <Page />
                    </PrivateLayout>
                  </Authmiddleware>
                }
              />
            );
          })}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
