import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updatePermission } from "../../store/permission/slice";

const ModalEditPermission = ({
  visible,
  onHide,
  rowData,
  selectedCheckboxes,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
  selectedCheckboxes: number[];
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: rowData?.name || "",
    },
    validationSchema: Yup.object().shape({
      groupName: Yup.string().required("Tên nhóm quyền không được để trống"),
    }),
    onSubmit: (value) => {
      dispatch(
        updatePermission({
          id: rowData?.id,
          name: value.groupName,
          permission_ids: selectedCheckboxes,
        })
      );
      onHide();
      formik.resetForm();
    },
  });

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      header="Chỉnh sửa nhóm quyền"
      modal
      style={{ width: "400px", height: "35%" }}
      footer={
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            type="button"
            onClick={() => {
              onHide();
              formik.resetForm();
            }}
            style={{ width: "80px" }}
          />
          <Button
            type="submit"
            label="Lưu"
            size="small"
            onClick={() => formik.handleSubmit()}
            style={{ width: "80px" }}
          />
        </div>
      }
    >
      <div className="bg-white border-round-md">
        <div className="flex flex-column gap-3">
          <div className="flex flex-column w-12">
            <label className="mb-2">Tên nhóm quyền</label>
            <InputText
              id="groupName"
              name="groupName"
              value={formik.values.groupName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.groupName && formik.errors.groupName
                  ? true
                  : false
              }
            />
            {formik.touched.groupName && formik.errors.groupName ? (
              <div style={{ color: "red" }}>
                {formik.errors.groupName.toString()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalEditPermission;
