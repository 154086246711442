/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { exportExcel, getContainerInfoData, setContainerDataNull } from "../../store/container/slice";
import { changeDateFormat } from "../../components/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function ModalDetailContainer({
  visible,
  onHide,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
}) {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [tabMenuActive, setTabmenuActive] = useState<number>(0);
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef<Toast>(null);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>("");

  const { containerInfoData, exportExcelContainerData, exportExcelContainerError } = useSelector(
    (state: any) => ({
      containerInfoData: state.Container.containerInfoData,
      loadingInfo: state.Container.loadingInfo,
      exportExcelContainerData: state.Container.exportExcelContainerData,
      exportExcelContainerError: state.Container.exportExcelContainerError,
    }),
    shallowEqual
  );

  const fetchData = (containerId: number, page: number, limit: number) => {
    dispatch(getContainerInfoData({ containerId, page, limit, query: {} }));
  };

  useEffect(() => {
    if (rowData && visible) {
      fetchData(rowData?.id, (first + rows) / rows, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, first, rows, rowData, visible]);

  const listStatus = [
    { label: "Kiện hàng chưa chuyển" },
    { label: "Kiện hàng đang chuyển" },
    { label: "Kiện hàng đã chuyển" },
  ];

  useEffect(() => {
    if (tabMenuActive === 0) {
      setDataTable(containerInfoData?.data[selectedRow]?.data_awating);
    } else if (tabMenuActive === 1) {
      setDataTable(containerInfoData?.data[selectedRow]?.data_transit);
    } else {
      setDataTable(containerInfoData?.data[selectedRow]?.data_delivered);
    }
  }, [tabMenuActive, selectedRow]);

  useEffect(() => {
    if (containerInfoData?.data && Array.isArray(containerInfoData.data) && containerInfoData.data.length > 0) {
      setDataTable(containerInfoData.data);
    }
  }, [dispatch, containerInfoData]);

  const handlePrintExport = () => {
    dispatch(
      exportExcel({
        type: `${tabMenuActive}`,
        data: dataTable,
      })
    );
  };

  useEffect(() => {
    if (exportExcelContainerData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `Xuất Excel thành công`,
        life: 3000,
      });
      const link = document.createElement("a");
      link.href = exportExcelContainerData.data;
      link.download = "File.xlsx";
      link.click();
      dispatch(setContainerDataNull());
    }
    if (exportExcelContainerError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${exportExcelContainerError.message}`,
        life: 3000,
      });
      dispatch(setContainerDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportExcelContainerData, exportExcelContainerError]);

  return (
    <div>
      <Dialog
        visible={visible}
        onHide={() => {
          onHide();
          setDataTable([]);
          setTabmenuActive(0);
          setRows(20);
          setIsShow(false);
          dispatch(setContainerDataNull());
        }}
        header="Chi tiết"
        className="w-20 md:w-6"
        // style={{ minHeight: "50vh" }}
      >
        <div className="flex gap-3 justify-content-between my-2">
          <div>
            <h4 className="" style={{ color: "#295bac" }}>
              {rowData?.name}
            </h4>
          </div>
          <div>
            <Button
              label="Xuất Excel"
              className="mr-3"
              icon="pi pi-print"
              severity="danger"
              style={{ height: "35px" }}
              onClick={() => handlePrintExport()}
              // disabled={!selectArrayPermisstion?.includes("IMPORT_EXCEL")}
            />
          </div>
        </div>
        <div className="bg-white border-round-md shadow-1 p-2">
          {isShow ? (
            <div>
              <div className="flex justify-content-between align-items-center ">
                <TabMenu
                  model={listStatus.map((item, index) => ({
                    ...item,
                    template: () => tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
                  }))}
                  activeIndex={tabMenuActive}
                  onTabChange={(e) => setTabmenuActive(e.index)}
                />
              </div>
              {
                // selectArrayPermisstion?.includes("VIEW_PACKAGE") ? (
                dataTable && (
                  <div>
                    <DataTable
                      value={dataTable}
                      emptyMessage="Không có dữ liệu"
                      size="small"
                      className="package-table"
                      scrollable
                      scrollHeight="calc(100vh - 22rem)"
                      style={{ height: "calc(100vh - 22rem)" }}
                    >
                      <Column
                        header="STT"
                        body={(rowData, options) => <div className="text-center">{first + options.rowIndex + 1}</div>}
                        style={{ width: "5%" }}
                        alignHeader={"center"}
                        align={"center"}
                        frozen
                      />
                      <Column field="carton_no" header="Mã kiện hàng"></Column>
                      <Column field="shopee_po_id" header="Shoppe PO ID"></Column>
                      <Column field="warehouse_name" header="Kho hàng"></Column>
                      <Column field="amount_product" header="Số lượng"></Column>
                      <Column
                        header="Ngày nhận"
                        body={(rowData) => <div>{changeDateFormat(rowData?.delivery_date)}</div>}
                      ></Column>
                    </DataTable>
                  </div>
                )
              }
            </div>
          ) : (
            <>
              {containerInfoData && dataTable ? (
                <div>
                  <DataTable
                    value={dataTable}
                    emptyMessage="Không có dữ liệu"
                    size="small"
                    className="package-table cursor-pointer"
                    reorderableColumns
                    onRowClick={(event) => {
                      setIsShow(true);
                      setSelectedRow(event.index);
                      setDataTable([]);
                    }}
                    scrollable
                    scrollHeight="calc(100vh - 22rem)"
                    style={{ height: "calc(100vh - 22rem)" }}
                  >
                    <Column
                      header="STT"
                      body={(rowData, options) => <div className="text-center">{first + options.rowIndex + 1}</div>}
                      style={{ width: "5%" }}
                      alignHeader={"center"}
                      align={"center"}
                      frozen
                    />
                    <Column field="warehouse_name" header="Tên kho"></Column>
                    <Column field="total_package" header="Tồng số kiện"></Column>
                    <Column field="total_awaiting" header="Tổng số kiện chưa chuyển"></Column>
                    <Column field="total_transit" header="Tổng số kiện đang chuyển"></Column>
                    <Column field="total_delivered" header="Tổng số kiện đã chuyển"></Column>
                  </DataTable>
                </div>
              ) : (
                <LoadingSpinner />
              )}
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}
