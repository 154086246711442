import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ShipmentState {
  shipmentPhaseData: any | null;
  listScanData: any | null;
  listScanDataError: any | null;
  error: any | null;
  loading: boolean;
  exportPrintListData: any | null;
  exportPrintListError: any | null;
}

const initialState: ShipmentState = {
  shipmentPhaseData: null,
  listScanData: null,
  error: null,
  listScanDataError: null,
  loading: false,
  exportPrintListData: null,
  exportPrintListError: null,
};

const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    getShipmentByPhaseData: (
      state,
      action: PayloadAction<{ tripId: number }>
    ) => {
      state.loading = true;
    },
    getShipmentByPhaseDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.shipmentPhaseData = action.payload;
      state.error = null;
    },
    getShipmentByPhaseDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.shipmentPhaseData = null;
      state.error = action.payload;
    },
    getListScanData: (
      state,
      action: PayloadAction<{
        shipment_id: number;
        s_global?: string;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getListScanSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.listScanData = action.payload;
      state.listScanDataError = null;
    },
    getListScanFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.listScanData = null;
      state.listScanDataError = action.payload;
    },
    exportPrintList: (state, action: PayloadAction<{ shipmentId: number }>) => {
      state.loading = true;
    },
    exportPrintListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportPrintListData = action.payload;
      state.exportPrintListError = null;
    },
    exportPrintListFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.exportPrintListData = null;
      state.exportPrintListError = action.payload;
    },
    setShipmentDataNull: (state) => {
      state.exportPrintListData = null;
      state.exportPrintListError = null;
      state.exportPrintListError = null;
    },
  },
});

export const {
  getShipmentByPhaseData,
  getShipmentByPhaseDataFail,
  getShipmentByPhaseDataSuccess,
  getListScanData,
  getListScanSuccess,
  getListScanFail,
  exportPrintList,
  exportPrintListSuccess,
  exportPrintListFail,
  setShipmentDataNull,
} = shipmentSlice.actions;
export default shipmentSlice.reducer;
