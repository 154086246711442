import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { insertCar, setCarDataNull } from "../../store/car/slice";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { optionsAddressSpx } from "../../components/Interface";

export default function ModelAddCars({
  visible,
  onHide,
  toast,
}: {
  visible: boolean;
  onHide: () => void;
  toast: any;
}) {
  const header = <div>Thêm xe</div>;
  const dispatch = useDispatch();
  const { insertCarData, insertCarError } = useSelector(
    (state: any) => ({
      insertCarData: state.Car.insertCarData,
      insertCarError: state.Car.insertCarError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      license_plate: "",
      capacity: "",
      address: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      license_plate: Yup.string().required("Vui lòng nhập biển số xe"),
      address: Yup.string().required("Vui lòng chọn địa chỉ"),
    }),
    onSubmit: (values: any) => {
      dispatch(insertCar(values));
    },
  });

  useEffect(() => {
    if (insertCarData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: insertCarData?.message,
        life: 3000,
      });
      onHide();
      dispatch(setCarDataNull());
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertCarData]);

  useEffect(() => {
    if (insertCarError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: insertCarError?.response?.data?.message,
        life: 3000,
      });
      dispatch(setCarDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertCarError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide();
        formik.resetForm();
      }}
      header={header}
      className="sm:w-5 w-8 xl:w-4 lg:w-4"
    >
      <div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="license_plate">
            Biển số xe <span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="license_plate"
            value={formik.values.license_plate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="license_plate"
            invalid={
              formik.touched.license_plate && formik.errors.license_plate
                ? true
                : false
            }
          />
          {formik.touched.license_plate && formik.errors.license_plate ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.license_plate)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="address">
            Địa chỉ hoạt động <span style={{ color: "red" }}>*</span>
          </label>
          <Dropdown
            value={formik.values.address}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("address", e.value);
            }}
            options={optionsAddressSpx}
            optionLabel="label"
            className="w-full"
            optionValue="code"
            placeholder="Chọn địa chỉ"
            invalid={
              formik.touched.address && formik.errors.address ? true : false
            }
            onBlur={formik.handleBlur}
          />
          {formik.touched.address && formik.errors.address ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.address)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="capacity">Trọng tải</label>
          <InputText
            id="capacity"
            value={formik.values.capacity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="capacity"
          />
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="description">Ghi chú</label>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="description"
            className="w-full"
          />
        </div>
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            onClick={() => {
              onHide();
              formik.resetForm();
            }}
            style={{ width: "80px" }}
          />
          <Button
            type="submit"
            onClick={() => formik.handleSubmit()}
            label="Lưu"
            size="small"
            style={{ width: "80px" }}
          />
        </div>
      </div>
    </Dialog>
  );
}
