import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { insertPlane, setPlaneDataNull } from "../../store/plane/slice";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { getWarehouseData } from "../../store/warehouse/slice";
import { optionsPlane } from "../../components/Interface";
interface Plane {
  code: string;
  departure_point: string;
  destination: string;
  description?: string;
}

export default function ModalAddPlane({
  visible,
  onHide,
  toast,
}: {
  visible: boolean;
  onHide: () => void;
  toast: any;
}) {
  const dispatch = useDispatch();
  const [optionWareHouse, setOptionWareHouse] = useState<any>(null);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);

  const { insertPlaneData, insertPlaneError, warehouseData, dataUserInfo } =
    useSelector(
      (state: any) => ({
        insertPlaneData: state.Plane.insertPlaneData,
        insertPlaneError: state.Plane.insertPlaneError,
        warehouseData: state.Warehouse.warehouseData,
        dataUserInfo: state.Auth.dataUserInfo,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch(
      getWarehouseData({
        page: 0,
        limit: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (warehouseData?.data && warehouseData.data.length > 0) {
      const mappedWarehouses: any[] = warehouseData.data.map(
        (warehouse: any) => ({
          label: warehouse.address, // hiển thị trong Dropdown
          code: warehouse.area, // giá trị được chọn
        })
      );

      setOptionWareHouse(mappedWarehouses);
    }
  }, [warehouseData]);

  // Validation schema
  const validationSchema = Yup.object({
    code: Yup.string().required("Vui lòng nhập số hiệu chuyến bay"),
    type: Yup.string().required("Vui lòng chọn loại tàu bay"),
    departure_point: Yup.string().required("Vui lòng chọn điểm khởi hành"),
    destination: Yup.string().required("Vui lòng chọn điểm đến"),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      type: "",
      departure_point: "",
      destination: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const planeData: Plane = {
        code: values.code,
        departure_point: values.departure_point,
        destination: values.destination,
        description: values.description,
      };
      dispatch(insertPlane(planeData));
      onHide();
    },
  });

  useEffect(() => {
    if (insertPlaneData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Thêm chuyến bay thành công",
        life: 3000,
      });
      formik.resetForm();
      onHide();
      dispatch(setPlaneDataNull());
    }
    if (insertPlaneError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: insertPlaneError?.response?.data?.message,
        life: 3000,
      });
      formik.resetForm();
      onHide();
      dispatch(setPlaneDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertPlaneData, insertPlaneError, dispatch]);

  useEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  return (
    <Dialog
      visible={visible}
      onHide={() => onHide()}
      header="Thêm mới chuyến bay"
      className="w-10 md:w-4"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="code">
            Số hiệu chuyến bay <span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="code"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.touched.code && formik.errors.code ? "p-invalid" : ""
            }
          />
          {formik.touched.code && formik.errors.code ? (
            <small className="p-error">{formik.errors.code}</small>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-1">
          <div className="flex flex-column gap-1 flex-1 mb-3">
            <label htmlFor="type">
              Loại tàu bay <span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={formik.values.type}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("type", e.value);
              }}
              options={optionsPlane}
              optionLabel="label"
              className="w-full"
              optionValue="code"
              placeholder="Chọn loại tàu bay"
              invalid={formik.touched.type && formik.errors.type ? true : false}
              onBlur={formik.handleBlur}
            />
            {formik.touched.type && formik.errors.type ? (
              <p
                style={{
                  color: "red",
                  marginTop: "5px",
                  fontSize: "0.9rem",
                  marginLeft: "0",
                  textAlign: "left",
                }}
              >
                {String(formik.errors.type)}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-1">
          <div className="flex flex-column gap-1 flex-1 mb-3">
            <label htmlFor="departure_point">
              Điểm khởi hành <span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={formik.values.departure_point}
              onChange={(e: DropdownChangeEvent) => {
                formik.setFieldValue("departure_point", e.value);
              }}
              options={optionWareHouse || []}
              optionLabel="label"
              className="w-full"
              optionValue="code"
              placeholder="Chọn địa điểm"
              invalid={
                formik.touched.departure_point && formik.errors.departure_point
                  ? true
                  : false
              }
              emptyMessage={
                !selectArrayPermisstion?.includes("VIEW_WAREHOUSE")
                  ? "Bạn chưa được cấp quyền xem kho"
                  : ""
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.departure_point && formik.errors.departure_point ? (
              <p
                style={{
                  color: "red",
                  marginTop: "5px",
                  fontSize: "0.9rem",
                  marginLeft: "0",
                  textAlign: "left",
                }}
              >
                {String(formik.errors.departure_point)}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="destination">
            Điểm đến <span style={{ color: "red" }}>*</span>
          </label>
          <Dropdown
            value={formik.values.destination}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("destination", e.value);
            }}
            options={optionWareHouse || []}
            optionLabel="label"
            className="w-full"
            optionValue="code"
            placeholder="Chọn địa điểm"
            invalid={
              formik.touched.destination && formik.errors.destination
                ? true
                : false
            }
            emptyMessage={
              !selectArrayPermisstion?.includes("VIEW_WAREHOUSE")
                ? "Bạn chưa được cấp quyền xem kho"
                : ""
            }
            onBlur={formik.handleBlur}
          />
          {formik.touched.destination && formik.errors.destination ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.destination)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="description">Ghi chú</label>
          <InputTextarea
            id="description"
            name="description"
            rows={4}
            className="w-full"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            type="button"
            onClick={() => {
              onHide();
              formik.resetForm();
            }}
            style={{ width: "80px" }}
          />
          <Button
            type="submit"
            label="Lưu"
            size="small"
            style={{ width: "80px" }}
          />
        </div>
      </form>
    </Dialog>
  );
}
