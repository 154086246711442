import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PlaneState {
  planeData: any | null;
  error: any | null;
  loading: boolean;
  insertPlaneData: any | null;
  insertPlaneError: any | null;
  deletePlaneData: any | null;
  deletePlaneError: any | null;
  updatePlaneData: any | null;
  updatePlaneError: any | null;
}

const initialState: PlaneState = {
  planeData: null,
  error: null,
  loading: false,
  insertPlaneData: null,
  insertPlaneError: null,
  deletePlaneData: null,
  deletePlaneError: null,
  updatePlaneData: null,
  updatePlaneError: null,
};

interface Plane {
  code: string;
  departure_point: string;
  destination: string;
  description?: string;
  // start_time: any | null;
  // end_time: any | null;
}

const planeSlice = createSlice({
  name: "plane",
  initialState,
  reducers: {
    getPlaneData: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getPlaneDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.planeData = action.payload;
      state.error = null;
    },
    getPlaneDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.planeData = null;
      state.error = action.payload;
    },
    insertPlane: (state, action: PayloadAction<Plane>) => {
      state.loading = true;
    },
    insertPlaneSuccess: (state, action: PayloadAction<Plane>) => {
      state.loading = false;
      state.insertPlaneData = action.payload;
      state.insertPlaneError = null;
    },
    insertPlaneFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertPlaneData = null;
      state.insertPlaneError = action.payload;
    },
    deletePlane: (state) => {
      state.loading = true;
    },
    deletePlaneSuccess: (state, action) => {
      state.loading = false;
      state.deletePlaneData = action.payload;
      state.deletePlaneError = null;
    },
    deletePlaneFail: (state, action) => {
      state.loading = false;
      state.deletePlaneData = null;
      state.deletePlaneError = action.payload;
    },
    updatePlane: (state, action: PayloadAction<{ id: string; data: any }>) => {
      state.loading = true;
    },
    updatePlaneSuccess: (state, action: PayloadAction<Plane>) => {
      state.loading = false;
      state.updatePlaneData = action.payload;
      state.updatePlaneError = null;
    },
    updatePlaneFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updatePlaneData = null;
      state.updatePlaneError = action.payload;
    },
    setPlaneDataNull: (state) => {
      state.insertPlaneData = null;
      state.insertPlaneError = null;
      state.deletePlaneData = null;
      state.deletePlaneError = null;
      state.updatePlaneData = null;
      state.updatePlaneError = null;
    },
  },
});

export const {
  getPlaneData,
  getPlaneDataFail,
  getPlaneDataSuccess,
  insertPlane,
  insertPlaneFail,
  insertPlaneSuccess,
  setPlaneDataNull,
  deletePlane,
  deletePlaneFail,
  deletePlaneSuccess,
  updatePlane,
  updatePlaneFail,
  updatePlaneSuccess,
} = planeSlice.actions;

export default planeSlice.reducer;
