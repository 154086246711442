import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { boolean } from "yup";
import { updateOnlyScreen } from "../../api/backend_helper";

interface NotificationState {
  notificationData: any | null;
  notificationOnlySeen: any | null;
  notificationAllSeen: any | null;
  notificationOnlySeenFail: any | null;
  notificationAllSeenFail: any | null;
  error: string | null;
  loading: boolean;
}

const initialState: NotificationState = {
  notificationData: null,
  notificationOnlySeen: null,
  notificationAllSeen: null,
  notificationOnlySeenFail: null,
  notificationAllSeenFail: null,
  error: null,
  loading: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotification: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getNotificationSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.notificationData = action.payload;
      state.error = null;
    },
    getNotificationFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.notificationData = null;
      state.error = action.payload;
    },
    updateOnlyNotification: (state, action: PayloadAction<{ id: number }>) => {
      state.loading = true;
    },
    updateOnlyScreenSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.notificationOnlySeen = action.payload;
      state.notificationOnlySeenFail = null;
    },
    updateOnlyScreenFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.notificationOnlySeen = null;
      state.notificationOnlySeenFail = action.payload;
    },
    updateAllScreenNotification: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    updateAllScreenNotificationSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.notificationAllSeen = action.payload;
      state.notificationAllSeenFail = null;
    },
    updateAllScreenNotificationFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.notificationAllSeen = null;
      state.notificationAllSeenFail = action.payload;
    },
  },
});

export const {
  getNotification,
  getNotificationSuccess,
  getNotificationFail,
  updateOnlyScreenSuccess,
  updateOnlyScreenFail,
  updateOnlyNotification,
  updateAllScreenNotification,
  updateAllScreenNotificationSuccess,
  updateAllScreenNotificationFail,
} = notificationSlice.actions;

export default notificationSlice.reducer;
