import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getTransitDataFail,
  getTransitDataSuccess,
} from "./slice";

import { getAllTransit, exportPrintList } from "../../api/backend_helper";

interface GetTransitPayload {
  page: number;
  limit: number;
  query?: any;
}

function* getTransitSaga(
  action: PayloadAction<GetTransitPayload>
): Generator<any, void, any> {
  try {
    const { page, limit, query = {} } = action.payload;
    const data = yield call(getAllTransit, page, limit, query);
    yield put(getTransitDataSuccess(data));
  } catch (error) {
    yield put(getTransitDataFail(error));
  }
}

function* TransitSaga(): Generator<any, void, any> {
  yield takeLatest("transit/getTransitData", getTransitSaga);
}

export default TransitSaga;
