import { Image } from "primereact/image";

function Header() {
  return (
    <div className="bg-white flex justify-content-center p-2">
      <div
        className="flex flex-row justify-content-center"
        style={{ height: "6rem" }}
      >
        <div className="align-items-center justify-content-center w-full h-4rem">
          <div>
            <Image src="images/logo/unnamed.png" alt="Image" width="60" />
          </div>
          <span
            className="font-bold text-blue-600 mt-2"
            style={{ fontSize: "11px" }}
          >
            Hxlogistics
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
