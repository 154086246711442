import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  insertCustomerSuccess,
  insertCustomerFail,
  getCustomerDataSuccess,
  getCustomerDataFail,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomerSuccess,
  deleteCustomerFail,
} from "./slice";

import {
  insertCustomer,
  getAllCustomer,
  updateCustomer,
  deleteCustomer,
} from "../../api/backend_helper";

interface CustomerProps {
  name: string;
  phone_number: string;
  email: string;
  address: string;
  description: string;
}

interface CustomerProps {
  name: string;
  phone_number: string;
  email: string;
  address: string;
  description: string;
  insertCustomerData: null;
  insertCustomerError: null;
}

function* fetchCarDataAll(
  action: PayloadAction<{ page: number; limit: number; query: {} }>
): Generator<any, void, any> {
  try {
    const { page, limit, query } = action.payload;
    const response = yield call(getAllCustomer, page, limit, query);
    yield put(getCustomerDataSuccess(response));
  } catch (error: any) {
    yield put(getCustomerDataFail(error));
  }
}

function* createCustomerSaga(
  action: PayloadAction<CustomerProps>
): Generator<any, void, any> {
  try {
    const data = yield call(insertCustomer, action.payload);
    yield put(insertCustomerSuccess(data));
  } catch (error) {
    yield put(insertCustomerFail(error));
  }
}

function* updateCustomerSaga(
  action: PayloadAction<{ id: number; data: CustomerProps }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateCustomer, id, data);
    yield put(updateCustomerSuccess(response));
  } catch (error) {
    yield put(updateCustomerFail(error));
  }
}

function* deleteCustomerSaga(
  action: PayloadAction<{ id: number }>
): Generator<any, void, any> {
  try {
    const { id } = action.payload;
    const response = yield call(deleteCustomer, id);
    yield put(deleteCustomerSuccess(response));
  } catch (error) {
    yield put(deleteCustomerFail(error));
  }
}

function* CustomerSaga(): Generator<any, void, any> {
  yield takeLatest("customer/getCustomerData", fetchCarDataAll);
  yield takeLatest("customer/insertCustomer", createCustomerSaga);
  yield takeLatest("customer/updateCustomer", updateCustomerSaga);
  yield takeLatest("customer/deleteCustomer", deleteCustomerSaga);
}

export default CustomerSaga;
