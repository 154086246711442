/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getListFileData, setPackageDataNull } from "../../store/listPackage/slice";
import { Calendar } from "primereact/calendar";
import { formatDateUser } from "../../components/utils";
import { deleteFileImport } from "../../store/listPackage/slice";
import ModelDelete from "../../components/ModalDelete";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment";

export default function ModalManageUploadFile({ visible, onHide }: { visible: boolean; onHide: () => void }) {
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const today = new Date();
  const [dates, setDates] = useState<[Date, Date] | null>([today, today]);
  const dispatch = useDispatch();

  const toast = useRef<Toast>(null);
  //xóa dữ liệu
  const handleDelete = (rowData: any) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.seri;
      if (id !== undefined) {
        dispatch(deleteFileImport(id));
      }
      setModalDelete(false);
    }
  };

  const { listFileData, deleteFileImportData, deleteFileImportError } = useSelector(
    (state: any) => ({
      listFileData: state.Package.listFileData,
      deleteFileImportData: state.Package.deleteFileImportData,
      deleteFileImportError: state.Package.deleteFileImportError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (visible) {
      dispatch(
        getListFileData({
          start_date: dates && dates[0] ? formatDateUser(dates[0]) : formatDateUser(today),
          end_date: dates && dates[1] ? formatDateUser(dates[1]) : formatDateUser(today),
        })
      );
    }
  }, [dispatch, dates, visible, deleteFileImportData]);

  useEffect(() => {
    if (deleteFileImportData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `${deleteFileImportData.message}`,
        life: 3000,
      });
      dispatch(setPackageDataNull());
    }
    if (deleteFileImportError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${deleteFileImportError.message}`,
        life: 3000,
      });
      dispatch(setPackageDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFileImportData, deleteFileImportError]);

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Dialog
        header="Quản lý file tải lên"
        visible={visible}
        style={{ width: "65vw" }}
        onHide={() => {
          if (!visible) return;
          onHide();
        }}
      >
        <div className="calendar-container w-5 mb-3 mt-2">
          <i className="pi pi-calendar text-500 mr-1" />
          <label className="w-max text-500" htmlFor="startDate" style={{ minWidth: "66px" }}>
            Ngày tải:
          </label>
          <div className="card flex justify-content-center ">
            <Calendar
              value={dates}
              onChange={(e: any) => setDates(e.value)}
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              dateFormat="dd/mm/yy"
              locale="vi"
              placeholder="Từ ngày - Đến ngày"
            />
          </div>
        </div>
        {listFileData && listFileData.data ? (
          <DataTable
            value={listFileData?.data}
            tableStyle={{ minWidth: "60rem" }}
            className="package-table"
            scrollable
            scrollHeight="calc(100vh - 20rem)"
            style={{ height: "calc(100vh - 20rem)" }}
            emptyMessage="Không có dữ liệu"
          >
            <Column field="origin_file_name" header="Tên file"></Column>
            <Column
              field="createdAt"
              header="Ngày tải lên"
              body={(row) => `${moment(row.createdAt).utcOffset(7).format("HH:mm DD/MM/YYYY")}`}
            ></Column>
            <Column
              field="action"
              header="Thao tác"
              align={"center"}
              body={(rowData) => (
                <i
                  className="pi pi-trash cursor-pointer"
                  style={{ color: "red" }}
                  onClick={() => handleDelete(rowData)}
                ></i>
              )}
            ></Column>
          </DataTable>
        ) : (
          <LoadingSpinner />
        )}
      </Dialog>
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={<div style={{ textAlign: "center" }}>Bạn chắc chắn muốn xóa File này?</div>}
      />
    </div>
  );
}
