import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CustomerProps {
  name: string;
  phone_number: string;
  email: string;
  address: string;
  description: string;
}

interface CustomerState {
  customerData: any | null;
  error: string | null;
  loading: boolean;
  insertCustomerData: any | null;
  insertCustomerError: any | null;
  updateCustomerData: any | null;
  updateCustomerError: any | null;
  deleteCustomerData: any | null;
  deleteCustomerError: any | null;
}

const initialState: CustomerState = {
  customerData: null,
  error: null,
  loading: false,
  insertCustomerData: null,
  insertCustomerError: null,
  updateCustomerData: null,
  updateCustomerError: null,
  deleteCustomerData: null,
  deleteCustomerError: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    getCustomerData: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getCustomerDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.customerData = action.payload;
      state.error = null;
    },
    getCustomerDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.customerData = null;
      state.error = action.payload;
    },
    insertCustomer: (state, action: PayloadAction<CustomerProps>) => {
      state.loading = true;
    },
    insertCustomerSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertCustomerData = action.payload;
      state.insertCustomerError = null;
    },
    insertCustomerFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertCustomerData = null;
      state.insertCustomerError = action.payload;
    },
    updateCustomer: (
      state,
      action: PayloadAction<{ id: number; data: CustomerProps }>
    ) => {
      state.loading = true;
    },
    updateCustomerSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateCustomerData = action.payload;
      state.updateCustomerError = null;
    },
    updateCustomerFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.updateCustomerData = null;
      state.updateCustomerError = action.payload;
    },
    deleteCustomer: (state, action: PayloadAction<{ id: number }>) => {
      state.loading = true;
    },
    deleteCustomerSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.deleteCustomerData = action.payload;
      state.deleteCustomerError = null;
    },
    deleteCustomerFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.deleteCustomerError = action.payload;
      state.deleteCustomerData = null;
    },
    setCustomerDataNull: (state) => {
      // state.carData = null;
      state.loading = false;
      state.error = null;
      state.insertCustomerData = null;
      state.insertCustomerError = null;
      state.updateCustomerData = null;
      state.updateCustomerError = null;
      state.deleteCustomerData = null;
      state.deleteCustomerError = null;
    },
  },
});

export const {
  insertCustomer,
  insertCustomerSuccess,
  insertCustomerFail,
  getCustomerData,
  getCustomerDataSuccess,
  getCustomerDataFail,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomer,
  deleteCustomerSuccess,
  deleteCustomerFail,
  setCustomerDataNull,
} = customerSlice.actions;

export default customerSlice.reducer;
