import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WarehouseState {
  warehouseData: any | null;
  error: any | null;
  loading: boolean;
  insertWarehouseData: any | null;
  insertWarehouseError: any | null;
  deleteWarehouseData: any | null;
  deleteWarehouseError: any | null;
  updateWarehouseData: any | null;
  updateWarehouseError: any | null;
}

const initialState: WarehouseState = {
  warehouseData: null,
  error: null,
  loading: false,
  insertWarehouseData: null,
  insertWarehouseError: null,
  deleteWarehouseData: null,
  deleteWarehouseError: null,
  updateWarehouseData: null,
  updateWarehouseError: null,
};

interface Warehouse {
  name: string;
  code: string;
  address: string;
  description: string;
}

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    getWarehouseData: (
      state,
      action: PayloadAction<{
        page?: number;
        limit?: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getWarehouseDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.warehouseData = action.payload;
      state.error = null;
    },
    getWarehouseDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.warehouseData = null;
      state.error = action.payload;
    },
    insertWarehouse: (state, action: PayloadAction<Warehouse>) => {
      state.loading = true;
    },
    insertWarehouseSuccess: (state, action: PayloadAction<Warehouse>) => {
      state.loading = false;
      state.insertWarehouseData = action.payload;
      state.insertWarehouseError = null;
    },
    insertWarehouseFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertWarehouseData = null;
      state.insertWarehouseError = action.payload;
    },
    deleteWarehouse: (state) => {
      state.loading = true;
    },
    deleteWarehouseSuccess: (state, action) => {
      state.loading = false;
      state.deleteWarehouseData = action.payload;
      state.deleteWarehouseError = null;
    },
    deleteWarehouseFail: (state, action) => {
      state.loading = false;
      state.deleteWarehouseData = null;
      state.deleteWarehouseError = action.payload;
    },
    updateWarehouse: (
      state,
      action: PayloadAction<{ id: string; data: any }>
    ) => {
      state.loading = true;
    },
    updateWarehouseSuccess: (state, action) => {
      state.loading = false;
      state.updateWarehouseData = action.payload;
      state.updateWarehouseError = null;
    },
    updateWarehouseFail: (state, action) => {
      state.loading = false;
      state.updateWarehouseData = null;
      state.updateWarehouseError = action.payload;
    },
    setWarehouseDataNull: (state) => {
      state.insertWarehouseData = null;
      state.insertWarehouseError = null;
      state.deleteWarehouseData = null;
      state.deleteWarehouseError = null;
      state.updateWarehouseData = null;
      state.updateWarehouseError = null;
    },
  },
});

export const {
  getWarehouseData,
  getWarehouseDataFail,
  getWarehouseDataSuccess,
  insertWarehouse,
  insertWarehouseFail,
  insertWarehouseSuccess,
  deleteWarehouse,
  deleteWarehouseFail,
  deleteWarehouseSuccess,
  updateWarehouse,
  updateWarehouseFail,
  updateWarehouseSuccess,
  setWarehouseDataNull,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
