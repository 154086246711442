const arrayReport = ["EXPORT_REPORT", "VIEW_REPORT"];
const arrayPackage = [
  "VIEW_PACKAGE",
  "CREATE_PACKAGE",
  "UPDATE_PACKAGE",
  "DELETE_PACKAGE",
  "IMPORT_EXCEL",
];
const arrayCar = ["VIEW_CAR", "CREATE_CAR", "UPDATE_CAR", "DELETE_CAR"];
const arrayWarehouse = [
  "VIEW_WAREHOUSE",
  "CREATE_WAREHOUSE",
  "UPDATE_WAREHOUSE",
  "DELETE_WAREHOUSE",
];
const arrayShipment = [
  "VIEW_SHIPMENT",
  "CREATE_SHIPMENT",
  "UPDATE_SHIPMENT",
  "DELETE_SHIPMENT",
];
const arrayPlane = [
  "VIEW_PLANE",
  "CREATE_PLANE",
  "UPDATE_PLANE",
  "DELETE_PLANE",
];
const arrayUser = ["VIEW_USER", "CREATE_USER", "UPDATE_USER", "DELETE_USER"];
const arrayPermission = [
  "VIEW_PERMISSION_GROUP",
  "CREATE_PERMISSION_GROUP",
  "UPDATE_PERMISSION_GROUP",
  "DELETE_PERMISSION_GROUP",
];
const arrayShipmentDetail = [
  "VIEW_SHIPMENT_DETAIL",
  "CREATE_SHIPMENT_DETAIL",
  "UPDATE_SHIPMENT_DETAIL",
  "DELETE_SHIPMENT_DETAIL",
];

const arrayCustomer = [
  "VIEW_CUSTOMER",
  "CREATE_CUSTOMER",
  "UPDATE_CUSTOMER",
  "DELETE_CUSTOMER",
];

export const hasCommonPermissionsReport = (selectArrayPermisstion: any) => {
  return arrayReport.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsPackage = (selectArrayPermisstion: any) => {
  return arrayPackage.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsCar = (selectArrayPermisstion: any) => {
  return arrayCar.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsWarehouse = (selectArrayPermisstion: any) => {
  return arrayWarehouse.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsShipment = (selectArrayPermisstion: any) => {
  return arrayShipment.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsPlane = (selectArrayPermisstion: any) => {
  return arrayPlane.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsUser = (selectArrayPermisstion: any) => {
  return arrayUser.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsPermission = (selectArrayPermisstion: any) => {
  return arrayPermission.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsShipmentDetail = (
  selectArrayPermisstion: any
) => {
  return arrayShipmentDetail.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};

export const hasCommonPermissionsCustomer = (selectArrayPermisstion: any) => {
  return arrayCustomer.some((permission) =>
    selectArrayPermisstion?.includes(permission)
  );
};
