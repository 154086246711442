import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

const LoadingSpinner = () => {
  return (
    <div className="flex align-items-center justify-content-center">
      <ProgressSpinner
        style={{ height: "40px", width: "40px" }}
        strokeWidth="4"
      />
    </div>
  );
};

export default LoadingSpinner;
