import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Dropdown } from "primereact/dropdown";
import "../../assets/scss/theme-base/table.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSearchDashBoard } from "../../store/dashboard/slice";
import { formatDateUser } from "../../components/utils";

const ChartVehicle = ({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) => {
  const [selectedTransPort, setSelectedTransPort] = useState(null);
  const dispatch = useDispatch();

  const vehicle = [
    { label: "Tất cả", code: "ALL" },
    { label: "Xe", code: "CAR" },
    { label: "Máy bay", code: "PLANE" },
  ];

  useEffect(() => {
    const today = new Date();
    const timeout = setTimeout(() => {
      const query: { [key: string]: string } = {};

      if (selectedTransPort) query.s_global = selectedTransPort;

      dispatch(
        getSearchDashBoard({
          start_date: startDate ? formatDateUser(startDate) : formatDateUser(today),
          end_date: endDate ? formatDateUser(endDate) : formatDateUser(today),
          ...(Object.keys(query).length > 0 && { query: query }),
        })
      );
    }, 400);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedTransPort, endDate, startDate]);

  const { searchDashboardData } = useSelector(
    (state: any) => ({
      searchDashboardData: state.Dashboard.searchDashboardData,
    }),
    shallowEqual
  );

  const options: ApexOptions = {
    // borderWidth: 0,
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    series: [
      searchDashboardData?.data?.total_in_transit || 0,
      searchDashboardData?.data?.total_completed_on_time || 0,
      searchDashboardData?.data?.total_completed_late || 0,
    ],
    labels: ["Đang vận chuyển", "Hoàn thành đúng giờ", "Hoàn thành trễ hạn"],
    colors: ["#04b52d", "#0000ff", "#ff0000"],
    dataLabels: { enabled: false },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
        },
        startAngle: 0,
        endAngle: 360,
      },
    },
  };

  return (
    <div
      className="px-4"
      // style={{ height: "calc(100vh - 10rem)", overflow: "auto" }}
    >
      <div className="flex justify-content-between align-items-center mb-5">
        <p className="font-semibold text-600">Phương tiện vận tải</p>
        <Dropdown
          value={selectedTransPort}
          name="label"
          placeholder="Lọc phương tiện"
          optionLabel="label"
          optionValue="code"
          className=" mr-2 w-5"
          style={{ height: "35px" }}
          onChange={(e) => setSelectedTransPort(e.value)}
          options={vehicle}
          showClear
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <p className="text-base font-semibold text-600">Tổng số chuyến</p>
      </div>
      <div style={{ justifyContent: "center" }}>
        <p
          style={{
            marginTop: "1rem",
            fontSize: "32px",
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
          }}
          className="mb-1"
        >
          {searchDashboardData?.data?.total_transit}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "calc(100vh - 33rem)",
          }}
        >
          <ReactApexChart options={options} series={options.series} type="donut" style={{ width: "45%" }} />
        </div>
        <div
          style={{
            justifyContent: "center",
            height: "10rem",
          }}
        >
          <div className="mt-4 flex justify-content-center">
            <div className="flex gap-1 mx-7 w-12 md:w-6" style={{ alignItems: "center" }}>
              <div
                className="dot"
                style={{
                  backgroundColor: "#04b52d",
                }}
              ></div>
              <p className="col-8">Đang vận chuyển</p>
              <p className="col-2 flex justify-content-end">{searchDashboardData?.data?.total_in_transit}</p>
            </div>
          </div>
          <div className=" flex justify-content-center">
            <div
              // key={item.status}
              className="flex gap-1 mx-7 w-12 md:w-6"
              style={{ alignItems: "center" }}
            >
              <div
                className="dot"
                style={{
                  // backgroundColor: `${item.color}`,
                  backgroundColor: "#0000ff",
                }}
              ></div>
              <p className="col-8">Hoàn thành đúng giờ</p>
              <p className="col-2 flex justify-content-end">{searchDashboardData?.data?.total_completed_on_time}</p>
            </div>
          </div>
          <div className="flex justify-content-center">
            <div className="flex gap-1 mx-7 w-12 md:w-6" style={{ alignItems: "center" }}>
              <div
                className="dot"
                style={{
                  backgroundColor: "#ff0000",
                }}
              ></div>
              <p className="col-8">Hoàn thành trễ hạn</p>
              <p className="col-2 flex justify-content-end">{searchDashboardData?.data?.total_completed_late}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartVehicle;
