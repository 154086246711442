/* eslint-disable @typescript-eslint/no-unused-vars */
import { TDocumentDefinitions } from "pdfmake/interfaces";
import logistic from "../../assets/images/avatas/logistic-banner.png";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { formatDatePDF } from "../../components/utils";

// Gán VFS (Virtual File System) cho pdfMake
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

interface PackageInfo {
  delivery_date: string;
  id: number;
  warehouse_id: number;
  shopee_po_id: string;
  carton_no: string;
  name: string;
  amount_product: number;
  amount_package: number;
  status: string;
  mark: string;
  seri: string;
  address_detail: string;
  employee_detail: string;
}

interface GroupedPackage {
  mark: string;
  seri: string;
  address_detail: string;
  employee_detail: string;
  packages: Array<{
    delivery_date: string;
    id: number;
    warehouse_id: number;
    shopee_po_id: string;
    carton_no: string;
    name: string;
    amount_product: number;
    amount_package: number;
    status: string;
  }>;
}

// Hàm chuyển đổi Blob thành Base64
const getBase64Image = (img: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("FileReader result is not a string"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(img);
  });
};

// Hàm lấy ảnh từ URL và chuyển thành Base64
const fetchImageAsBase64 = async (imagePath: string): Promise<any> => {
  try {
    const response = await fetch(imagePath);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const blob = await response.blob();
    return await getBase64Image(blob);
  } catch (error) {
    return null;
  }
};

// Cấu hình font chữ (đảm bảo rằng font đã được cấu hình trong vfs_fonts)
(pdfMake as any).fonts = {
  NotoSansSC: {
    normal: "NotoSansSC-Regular.ttf",
    bold: "NotoSansSC-Bold.ttf",
    italics: "NotoSansSC-Regular.ttf",
    bolditalics: "NotoSansSC-Bold.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Bold.ttf",
    italics: "Roboto-Regular.ttf",
    bolditalics: "Roboto-Bold.ttf",
  },
};

// Hàm xuất PDF
export const exportTransitPdf = async (
  action: "print" | "save",
  dataTable: any
): Promise<void> => {
  const logoBase64 = await fetchImageAsBase64(logistic);
  const groupPackages = (groupBySeri: {
    [key: string]: { package_info: PackageInfo }[];
  }): GroupedPackage[] => {
    const result: GroupedPackage[] = [];
    Object.keys(groupBySeri).forEach((key) => {
      const firstPackage = groupBySeri[key][0].package_info;
      const commonInfo: GroupedPackage = {
        mark: firstPackage.mark,
        seri: firstPackage.seri,
        address_detail: firstPackage.address_detail,
        employee_detail: firstPackage.employee_detail,
        packages: groupBySeri[key].map((pkg) => ({
          delivery_date: pkg.package_info.delivery_date,
          id: pkg.package_info.id,
          warehouse_id: pkg.package_info.warehouse_id,
          shopee_po_id: pkg.package_info.shopee_po_id,
          carton_no: pkg.package_info.carton_no,
          name: pkg.package_info.name,
          amount_product: pkg.package_info.amount_product,
          amount_package: pkg.package_info.amount_package,
          status: pkg.package_info.status,
        })),
      };
      (commonInfo as any).groupKey = key;
      result.push(commonInfo);
    });

    return result;
  };

  const groupedData = groupPackages(dataTable);

  // Tạo một mảng để lưu trữ các tài liệu PDF
  const pdfDocGenerators: any[] = [];

  let grandTotalAmountProduct = 0;
  let grandTotalAmountPackage = 0;
  // Tạo PDF cho mỗi nhóm dữ liệu
  for (const group of groupedData) {
    // Tính tổng số lượng sản phẩm và số kiện hàng trong group hiện tại
    const totalAmountProduct = group.packages.reduce(
      (total, item) => total + item.amount_product,
      0
    );
    const totalAmountPackage = group.packages.reduce(
      (total, item) => total + item.amount_package,
      0
    );

    // Cộng dồn vào tổng toàn bộ
    grandTotalAmountProduct += totalAmountProduct;
    grandTotalAmountPackage += totalAmountPackage;

    const docDefinition: TDocumentDefinitions = {
      pageSize: { width: 1100, height: 1800 },
      content: [
        {
          image: logoBase64,
          width: 1020,
          height: 150,
        },
        {
          columns: [
            {
              text: [{ text: `${group.mark}`, font: "NotoSansSC" }],
              style: "header",
              fontSize: 14,
              margin: [0, 10, 0, 5],
              width: "*",
              bold: true,
              font: "Roboto",
            },
            {
              text: [{ text: `${group.seri}`, font: "NotoSansSC" }],
              style: "end",
              bold: true,
              fontSize: 14,
              margin: [0, 10, 60, 0],
              alignment: "right",
            },
          ],
        },
        {
          text: [{ text: `${group.address_detail}`, font: "NotoSansSC" }],
          style: "header",
          fontSize: 10,
          margin: [0, 10, 0, 10],
        },
        {
          text: [{ text: `${group.employee_detail}`, font: "NotoSansSC" }],
          style: "header",
          fontSize: 10,
          margin: [0, 10, 0, 10],
        },
        {
          table: {
            headerRows: 1, // Số hàng tiêu đề cần được lặp lại
            // dontBreakRows: true, // Ngăn cắt các hàng giữa các trang
            widths: ["auto", 100, 175, 200, 100, 150, 200],
            body: [
              [
                {
                  text: "STT",
                  bold: true,
                  alignment: "center",
                  fillColor: "#295bca",
                  color: "#ffffff",
                  fontSize: 11,
                  margin: [0, 20, 0, 5],
                },
                {
                  text: "SHOPEE PO ID",
                  bold: true,
                  alignment: "center",
                  fillColor: "#295bca",
                  color: "#ffffff",
                  fontSize: 11,
                  margin: [0, 20, 0, 5],
                  font: "Roboto",
                },
                {
                  text: [
                    { text: "Số thông\n", font: "Roboto" },
                    { text: "箱号\n", font: "NotoSansSC" },
                    { text: "CARTON NO.", font: "Roboto" },
                  ],
                  bold: true,
                  fillColor: "#295bca",
                  alignment: "center",
                  color: "#ffffff",
                  fontSize: 11,
                  margin: [0, 5, 0, 5],
                },
                {
                  text: [
                    { text: "tên hàng\n", font: "Roboto" },
                    { text: "货名\n", font: "NotoSansSC" },
                    { text: "DESCRIPTION OF GOODS", font: "Roboto" },
                  ],
                  bold: true,
                  fillColor: "#295bca",
                  alignment: "center",
                  color: "#ffffff",
                  fontSize: 11,
                  margin: [0, 5, 0, 5],
                },
                {
                  text: "Parcel Pcs",
                  bold: true,
                  fontSize: 11,
                  fillColor: "#295bca",
                  alignment: "center",
                  color: "#ffffff",
                  margin: [0, 20, 0, 5],
                  font: "Roboto",
                },
                {
                  text: [
                    { text: "Ngày giao hàng\n", font: "Roboto" },
                    { text: "交货日期\n", font: "NotoSansSC" },
                    { text: "DATE OF RECEIVED", font: "Roboto" },
                  ],
                  bold: true,
                  fillColor: "#295bca",
                  alignment: "center",
                  fontSize: 11,
                  color: "#ffffff",
                  margin: [0, 5, 0, 5],
                },
                {
                  text: [
                    { text: "số kiện\n", font: "Roboto" },
                    { text: "件数\n", font: "NotoSansSC" },
                    { text: "NUMBER OF PACKAGES", font: "Roboto" },
                  ],
                  bold: true,
                  fillColor: "#295bca",
                  alignment: "center",
                  fontSize: 11,
                  color: "#ffffff",
                  margin: [0, 5, 0, 5],
                },
              ],
              ...group.packages.map((item, index) => [
                {
                  text: (index + 1).toString(),
                  alignment: "center",
                  margin: [0, 0, 0, 0],
                  fontSize: 12,
                  bold: true,
                },
                {
                  text: item.shopee_po_id,
                  margin: [0, 0, 0, 0],
                  alignment: "center",
                  fontSize: 12,
                  bold: true,
                },
                {
                  text: item.carton_no,
                  margin: [0, 0, 0, 0],
                  bold: true,
                  fontSize: 12,
                  // alignment: "center",
                },
                {
                  text: item.name,
                  margin: [0, 0, 0, 0],
                  alignment: "center",
                  fontSize: 12,
                  bold: true,
                },
                {
                  text: item.amount_product.toString(),
                  margin: [0, 0, 0, 0],
                  alignment: "center",
                  fontSize: 12,
                  bold: true,
                },
                // index === 0
                //   ? {
                //       text: group.packages[0].delivery_date,
                //       margin: [0, 0, 0, 5],
                //       alignment: "center",
                //       vAlign: "middle",
                //       rowSpan: group.packages.length,
                //     }
                //   : {},
                {
                  text: formatDatePDF(item.delivery_date),
                  margin: [0, 0, 0, 0],
                  alignment: "center",
                  fontSize: 12,
                  bold: true,
                },
                {
                  text: {
                    text: item.amount_package.toString(),
                    font: "Roboto",
                  },
                  bold: true,
                  fontSize: 12,
                  margin: [0, 0, 0, 0],
                  alignment: "center",
                },
              ]),
              [
                {},
                {
                  text: [
                    { text: "Hợp kế\n", font: "Roboto" },
                    { text: "合计\n", font: "NotoSansSC" },
                    { text: "TOTAL:", font: "Roboto" },
                  ],
                  colSpan: 1,
                  alignment: "center",
                  bold: true,
                  fontSize: 8,
                  margin: [0, 0, 0, 0],
                },
                {},
                {},
                {
                  text: `${grandTotalAmountProduct} PKGS`,
                  alignment: "center",
                  bold: true,
                  fontSize: 11,
                  margin: [0, 10, 0, 0],
                },
                {},
                {
                  text: `${totalAmountPackage}`, // Hiển thị tổng số kiện hàng của group này
                  alignment: "center",
                  bold: true,
                  fontSize: 11,
                  margin: [0, 10, 0, 0],
                },
              ],
              [
                {
                  text: [
                    { text: "thời gian ký\n", font: "Roboto" },
                    { text: "签收时间\n", font: "NotoSansSC" },
                    { text: "(DATE):", font: "Roboto" },
                  ],
                  colSpan: 4, // Adjust the colSpan to merge 4 cells
                  alignment: "center",
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                {}, // Empty cells to be merged
                {},
                {},
                {
                  text: [
                    { text: "người nhận hàng ký tên\n", font: "Roboto" },
                    { text: "收货人签名\n", font: "NotoSansSC" },
                    { text: "(SIGNATURE):", font: "Roboto" },
                  ],
                  colSpan: 3,
                  alignment: "center",
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                {},
                {},
              ],
            ],
          },
        },
      ],
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerators.push(pdfDocGenerator); // Lưu pdf generator vào mảng
  }

  // Tải xuống từng tài liệu PDF theo thứ tự
  if (action === "save") {
    for (let i = 0; i < pdfDocGenerators.length; i++) {
      const generator = pdfDocGenerators[i];
      // const filename = `${groupedData[i].seri || "default"}.pdf`;
      const filename = `${(groupedData[i] as any).groupKey || "default"}.pdf`;
      await new Promise((resolve) => {
        generator.getBlob((blob: Blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          resolve(null); // Thông báo hoàn tất tải xuống
        });
      });
    }
  } else if (action === "print") {
    // Xử lý print tương tự như trước
    for (const generator of pdfDocGenerators) {
      generator.getBlob((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, "_blank");
        if (newWindow) {
          newWindow.onload = () => {
            newWindow.print();
          };
        }
      });
    }
  }
};
