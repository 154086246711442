import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/theme-base/table.scss";
import { getTransitData } from "../../store/transit/slice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { statusTemplate } from "../../components/status";
import { IconPlaneCustom, IconTransitCustom } from "../../components/Icon";
import { DataContext } from "../../contexts/data/DataProvider";

export function changeDateFormat(dateString: string): string {
  if (typeof dateString !== "string") {
    return "";
  }
  const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
  const match = dateString.match(regex);

  if (!match) {
    return "";
  }

  const [, year, month, day, hour, minute] = match;
  return `${day}/${month}/${year} ${hour}:${minute}`;
}

interface transportProps {
  name: string;
  code: string;
}

interface Product {
  id: string;
  name: string;
  capacity: string;
  name_driver: string;
  phone_driver: string;
  trip_id: string;
  start_date: any;
  end_date: any;
  status: string;
  actual_time: any;
  shipment_id: string;
  shipment_before_id: string;
}

export default function Transit() {
  const dispatch = useDispatch();

  const [tabMenuActive, setTabMenuActive] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(20);
  const [dataTable, setDataTable] = useState([]);
  const [s_address, setSAddress] = useState<string>("");
  const [inputSearch, setInputSearch] = useState<string>("");
  const navigate = useNavigate();
  const [activePackage, setActivePackage] = useState<boolean>(false);
  const { selectedTransPort, setSelectedTransPort } = useContext(DataContext);

  useEffect(() => {
    const addressMap: Record<number, string> = {
      0: "",
      1: "HA_NOI",
      2: "HO_CHI_MINH",
      3: "DA_NANG",
      4: "CAN_THO",
    };
    setSAddress(addressMap[tabMenuActive] || "");
  }, [tabMenuActive]);

  const { transitData, dataUserInfo } = useSelector(
    (state: any) => ({
      transitData: state.Transit.transitData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  const transport: transportProps[] = [
    { name: "Đang vận chuyển", code: "IN_TRANSIT" },
    { name: "Hoàn thành", code: "DELIVERED" },
  ];

  // const [selectedTransPort, setSelectedTransPort] = useState<transportProps | null>(transport[0]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const query: { [key: string]: string | boolean } = {};

      if (inputSearch) query.s_global = inputSearch;
      if (s_address) query.s_address = s_address;
      query.active = activePackage;

      if (Object.keys(query).length > 0) {
        dispatch(
          getTransitData({
            page: (first + rows) / rows,
            limit: rows,
            query: query,
          })
        );
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [dispatch, rows, first, s_address, inputSearch, activePackage]);

  useEffect(() => {
    if (transitData && transitData.data) {
      // Kiểm tra dữ liệu và xử lý nếu cần
      setDataTable(transitData.data);
    }
  }, [transitData]);

  const items = [
    { label: "Tất cả" },
    { label: "Hà Nội" },
    { label: "Hồ Chí Minh" },
    { label: "Đà Nẵng" },
    { label: "Cần Thơ" },
  ];

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleNavigateClick = (rowData: Product) => {
    navigate("/detail-transit");
    localStorage.setItem("productData", JSON.stringify(rowData));
  };

  const rowClassName = (rowData: any, rowIndex: any) => {
    return "custom-row";
  };

  useEffect(() => {
    if (selectedTransPort?.code === "IN_TRANSIT") {
      setActivePackage(true);
    } else {
      setActivePackage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransPort]);

  return (
    <div>
      <div className="bg-white border-round-md shadow-1 p-3 ">
        <div>
          <div className="flex items-center gap-3 justify-content-between align-items-center w-full">
            <div>
              <TabMenu
                model={items.map((item, index) => ({
                  ...item,
                  template: () => tabMenuTemplate(item, index, tabMenuActive, setTabMenuActive),
                }))}
                activeIndex={tabMenuActive}
                onTabChange={(e) => setTabMenuActive(e.index)}
                pt={{
                  menuitem: { className: "p-0" },
                }}
              />
            </div>
            <div className="flex items-center justify-center w-6">
              <Dropdown
                value={selectedTransPort}
                onChange={(e: DropdownChangeEvent) => setSelectedTransPort(e.value)}
                options={transport}
                optionLabel="name"
                placeholder="Đang vận chuyển"
                className=" mr-2 w-4"
                style={{ height: "35px" }}
                // showClear
              />
              <IconField iconPosition="left" className="search-item flex items-center w-8">
                <InputIcon className="pi pi-search"></InputIcon>
                <InputText
                  placeholder="Tìm kiếm"
                  className="border-1"
                  style={{
                    height: "35px",
                  }}
                  onChange={(e) => setInputSearch(e.target.value)}
                />
              </IconField>
            </div>
          </div>
        </div>
        <div className="bg-white border-round-md">
          {transitData && transitData.data ? (
            <DataTable
              value={dataTable}
              tableStyle={{ minWidth: "50rem" }}
              onRowClick={(e: any) => handleNavigateClick(e.data)}
              rowClassName={rowClassName}
              className="package-table"
              emptyMessage="Không có dữ liệu"
              scrollable
              scrollHeight="calc(100vh - 16rem)"
              style={{ height: "calc(100vh - 16rem)" }}
            >
              <Column
                header="STT"
                body={(rowData, options) => <div className="text-center">{first + options.rowIndex + 1}</div>}
                style={{ width: "5%" }}
                alignHeader={"center"}
                align={"center"}
              />
              <Column
                header="BKS/ Số chuyến bay"
                style={{ minWidth: "180px" }}
                body={(rowData) => (
                  <div>
                    {rowData.car_info ? (
                      <div className="flex flex-row align-items-center py-2 gap-2">
                        {IconTransitCustom}
                        <div>{rowData?.car_info?.name}</div>
                      </div>
                    ) : (
                      <div className="flex flex-row align-items-center py-2 gap-2">
                        {IconPlaneCustom}
                        <div>{rowData?.plane_info?.name}</div>
                      </div>
                    )}
                  </div>
                )}
              />
              <Column
                field="start"
                header="Xuất phát"
                style={{ minWidth: "180px" }}
                body={(rowData) => (
                  <div>{rowData.plane_info ? changeDateFormat(rowData.plane_info.start_time) : ""}</div>
                )}
              ></Column>
              <Column
                header="Đến nơi"
                style={{ minWidth: "180px" }}
                body={(rowData) => <div>{rowData.plane_info ? changeDateFormat(rowData.plane_info.end_time) : ""}</div>}
              ></Column>
              <Column field="car_info.name_driver" header="Tài xế" style={{ minWidth: "180px" }}></Column>
              <Column field="car_info.phone_driver" header="Liên hệ" style={{ minWidth: "120px" }}></Column>
              <Column field="car_info.capacity" header="Tải trọng" style={{ minWidth: "120px" }}></Column>
              <Column
                field="number_of_package"
                header="Tổng số kiện hàng"
                frozen
                style={{ minWidth: "100px" }}
              ></Column>
              <Column field="description" header="Ghi chú" frozen style={{ minWidth: "100px" }}></Column>

              <Column
                field="status"
                header="Trạng thái"
                align={"center"}
                style={{ minWidth: "120px" }}
                body={statusTemplate}
              />
            </DataTable>
          ) : (
            <LoadingSpinner />
          )}
          <Paginator
            first={first}
            rows={rows}
            totalRecords={transitData?.total}
            rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
}
