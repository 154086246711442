import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import {
  insertCustomer,
  setCustomerDataNull,
} from "../../store/customer/slice";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

export default function ModelAddCustomer({
  visible,
  onHide,
  toast,
}: {
  visible: boolean;
  onHide: () => void;
  toast: any;
}) {
  const header = <div>Thêm khách hàng</div>;
  const dispatch = useDispatch();
  const { insertCustomerData, insertCustomerError } = useSelector(
    (state: any) => ({
      insertCustomerData: state.Customer.insertCustomerData,
      insertCustomerError: state.Customer.insertCustomerError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      phone_number: "",
      email: "",
      address: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Vui lòng nhập tên khách hàng"),
      phone_number: Yup.string()
        .required("Vui lòng nhập số điện thoại")
        .matches(/^\d+$/, "Số điện thoại phải là một số")
        .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
        .max(11, "Số điện thoại chỉ được phép có tối đa 11 chữ số"),
      email: Yup.string()
        .required("Vui lòng nhập email")
        .email("Email không hợp lệ"),
    }),
    onSubmit: (values: any) => {
      dispatch(insertCustomer(values));
    },
  });

  useEffect(() => {
    if (insertCustomerData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: insertCustomerData?.message,
        life: 3000,
      });
      onHide();
      dispatch(setCustomerDataNull());
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertCustomerData]);

  useEffect(() => {
    if (insertCustomerError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: insertCustomerError?.response?.data?.message,
        life: 3000,
      });
      dispatch(setCustomerDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertCustomerError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide();
        formik.resetForm();
      }}
      header={header}
      className="sm:w-5 w-8 xl:w-4 lg:w-4"
    >
      <div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="name">
            Tên khách hàng <span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            invalid={formik.touched.name && formik.errors.name ? true : false}
          />
          {formik.touched.name && formik.errors.name ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.name)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="phone_number">
            Số điện thoại <span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="phone_number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="phone_number"
            invalid={
              formik.touched.phone_number && formik.errors.phone_number
                ? true
                : false
            }
          />
          {formik.touched.phone_number && formik.errors.phone_number ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.phone_number)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="email">
            Email <span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
            invalid={formik.touched.email && formik.errors.email ? true : false}
          />
          {formik.touched.email && formik.errors.email ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.email)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="address">Địa chỉ</label>
          <InputText
            id="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="address"
          />
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="description">Ghi chú</label>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="description"
            className="w-full"
          />
        </div>
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            onClick={() => {
              onHide();
              formik.resetForm();
            }}
            style={{ width: "80px" }}
          />
          <Button
            type="submit"
            onClick={() => formik.handleSubmit()}
            label="Lưu"
            size="small"
            style={{ width: "80px" }}
          />
        </div>
      </div>
    </Dialog>
  );
}
