// import Statistical from "../pages/Report";
import Report from "../pages/Report";
import Warehouse from "../pages/Warehouse";
import Car from "../pages/Car";
import Plane from "../pages/Plane";
import Permission from "../pages/Permission";
import Login from "../pages/Authentication/Login";
import Package from "../pages/ListPackage";
import Transit from "../pages/Transit";
import User from "../pages/User";
import DetailTransit from "../pages/Transit/detailTransit";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/Authentication/ChangePassword";
import { BlankPage } from "../pages/BlankPage";
import Container from "../pages/Container";
import Customer from "../pages/Customer";
import Dashboard from "../pages/Dashboard";

//Public Routes
const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/blankpage", component: BlankPage },
];

//Private Routes
const privateRoutes = [
  { path: "/", component: Dashboard },
  { path: "/report", component: Report },
  { path: "/warehouse", component: Warehouse },
  { path: "/car", component: Car },
  { path: "/plane", component: Plane },
  { path: "/permission", component: Permission },
  { path: "/package", component: Package },
  { path: "/transit", component: Transit },
  { path: "/container", component: Container },
  { path: "/user", component: User },
  { path: "/detail-transit", component: DetailTransit },
  { path: "/profile", component: Profile },
  { path: "/change-password", component: ChangePassword },
  { path: "/customer", component: Customer },
];

export { publicRoutes, privateRoutes };
