import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { setCarDataNull, updateCar } from "../../store/car/slice";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { optionsAddressSpx } from "../../components/Interface";

type CarProps = {
  id: string;
  name: string;
  license_plate: string;
  capacity: string;
  address: string;
  description: string;
};

export default function ModelEditCars({
  visible,
  onHide,
  rowData,
  toast,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: CarProps;
  toast: any;
}) {
  const header = <div>Chỉnh sửa xe</div>;
  const dispatch = useDispatch();
  const { updateCarData, updateCarError } = useSelector(
    (state: any) => ({
      updateCarData: state.Car.updateCarData,
      updateCarError: state.Car.updateCarError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      license_plate: rowData ? rowData.license_plate : "",
      capacity: rowData ? rowData.capacity : "",
      address: rowData ? rowData.address : "",
      description: rowData ? rowData.description : "",
    },
    validationSchema: Yup.object().shape({
      license_plate: Yup.string().required("Vui lòng nhập biển số xe"),
      address: Yup.string().required("Vui lòng chọn địa chỉ"),
    }),
    onSubmit: async (values: any) => {
      dispatch(updateCar({ id: rowData?.id, data: values }));
    },
  });

  useEffect(() => {
    if (updateCarData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `${updateCarData.message}`,
        life: 3000,
      });
    }
    onHide();
    dispatch(setCarDataNull());
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCarData]);

  useEffect(() => {
    if (updateCarError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: updateCarError?.response?.data?.message,
        life: 3000,
      });
    }
    dispatch(setCarDataNull());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCarError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide();
        formik.resetForm();
      }}
      header={header}
      className="sm:w-5 w-8 xl:w-4 lg:w-4"
    >
      <div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="license_plate">
            Biển số xe <span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="license_plate"
            value={formik.values.license_plate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="license_plate"
            invalid={
              formik.touched.license_plate && formik.errors.license_plate
                ? true
                : false
            }
          />
          {formik.touched.license_plate && formik.errors.license_plate ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.license_plate)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="address">
            Địa chỉ hoạt động <span style={{ color: "red" }}>*</span>
          </label>
          <Dropdown
            value={optionsAddressSpx.find(
              (e) => e.code === formik.values.address
            )}
            onChange={(e: DropdownChangeEvent) => {
              formik.setFieldValue("address", e.value.code);
            }}
            options={optionsAddressSpx}
            optionLabel="label"
            className="w-full"
            placeholder="Chọn địa chỉ"
            invalid={
              formik.touched.address && formik.errors.address ? true : false
            }
            onBlur={formik.handleBlur}
          />
          {formik.touched.address && formik.errors.address ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.address)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="capacity">Trọng tải</label>
          <InputText
            id="capacity"
            value={formik.values.capacity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="capacity"
          />
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="description">Ghi chú</label>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="description"
            className="w-full"
          />
        </div>
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            onClick={() => {
              onHide();
              formik.resetForm();
            }}
            style={{ width: "80px" }}
          />
          <Button
            type="submit"
            onClick={() => formik.handleSubmit()}
            label="Lưu"
            size="small"
            style={{ width: "80px" }}
          />
        </div>
      </div>
    </Dialog>
  );
}
