import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Button } from "primereact/button";
import ModalAddWarehouse from "./ModalAddWarehouse";
import ModalEditWarehouse from "./ModalEditWarehouse";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Paginator } from "primereact/paginator";
import ModelDelete from "../../components/ModalDelete";
import {
  getWarehouseData,
  deleteWarehouse,
  setWarehouseDataNull,
} from "../../store/warehouse/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ActionMenu from "../../components/ActionMenu";
import { Toast } from "primereact/toast";
import LoadingSpinner from "../../components/LoadingSpinner";
import { IconWarehouseCustom } from "../../components/Icon";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";

const listMenu = [
  { label: "Kho SPX" },
  { label: "Kho trung chuyển" },
  { label: "Kho thông quan" },
];

function Warehouse() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);

  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [dataTable, setDataTable] = useState([]);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [tabMenuActive, setTabmenuActive] = useState(0);

  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const {
    warehouseData,
    insertWarehouseData,
    deleteWarehouseData,
    deleteWarehouseError,
    updateWarehouseData,
    dataUserInfo,
  } = useSelector(
    (state: any) => ({
      warehouseData: state.Warehouse.warehouseData,
      insertWarehouseData: state.Warehouse.insertWarehouseData,
      deleteWarehouseData: state.Warehouse.deleteWarehouseData,
      deleteWarehouseError: state.Warehouse.deleteWarehouseError,
      updateWarehouseData: state.Warehouse.updateWarehouseData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputSearch) {
        dispatch(
          getWarehouseData({
            page: (first + rows) / rows,
            limit: rows,
            query: {
              s_global: inputSearch,
              type:
                tabMenuActive === 0
                  ? "SPX"
                  : tabMenuActive === 1
                  ? "TRANSIT"
                  : "CUSTOMS_CLEARANCE",
            },
          })
        );
      } else {
        dispatch(
          getWarehouseData({
            page: (first + rows) / rows,
            limit: rows,
            query: {
              type:
                tabMenuActive === 0
                  ? "SPX"
                  : tabMenuActive === 1
                  ? "TRANSIT"
                  : "CUSTOMS_CLEARANCE",
            },
          })
        );
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [dispatch, rows, first, inputSearch, tabMenuActive]);

  useEffect(() => {
    if (insertWarehouseData || deleteWarehouseData || updateWarehouseData) {
      dispatch(
        getWarehouseData({
          page: (first + rows) / rows,
          limit: rows,
          query: {
            type:
              tabMenuActive === 0
                ? "SPX"
                : tabMenuActive === 1
                ? "TRANSIT"
                : "CUSTOMS_CLEARANCE",
          },
        })
      );
    }
  }, [
    dispatch,
    rows,
    first,
    insertWarehouseData,
    deleteWarehouseData,
    updateWarehouseData,
    tabMenuActive,
  ]);

  useEffect(() => {
    setDataTable(warehouseData?.data);
  }, [warehouseData]);
  //sửa dữ liệu
  const handleEdit = (rowData: any) => {
    if (selectArrayPermisstion?.includes("UPDATE_WAREHOUSE")) {
      setModalEdit(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  //xóa dữ liệu
  const handleDelete = (rowData: any) => {
    if (selectArrayPermisstion?.includes("DELETE_WAREHOUSE")) {
      setModalDelete(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deleteWarehouse(id));
      }
      setModalDelete(false);
    }
  };

  //Menu hành động
  const actionBodyTeamplate = (rowData: any) => {
    return (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
        type=""
      />
    );
  };

  useEffect(() => {
    if (deleteWarehouseData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Xóa kho hàng thành công",
        life: 3000,
      });
      dispatch(setWarehouseDataNull());
    }
    if (deleteWarehouseError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: deleteWarehouseData.response?.data?.message,
        life: 3000,
      });
      dispatch(setWarehouseDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteWarehouseData, deleteWarehouseError]);

  const areaMap: { [key: string]: string } = {
    HO_CHI_MINH: "Hồ Chí Minh",
    HA_NOI: "Hà Nội",
    DA_NANG: "Đà Nẵng",
    CAN_THO: "Cần Thơ",
  };

  const areaBodyTemplate = (rowData: any) => {
    return areaMap[rowData.area] || rowData.area;
  };

  const warehouseTemplate = (rowData: any) => {
    return (
      <div className="flex flex-row align-items-center py-2 gap-2">
        {IconWarehouseCustom}
        <div>{rowData?.name}</div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-content-between mb-3">
        <div className="flex gap-3 w-8">
          <Button
            label="Thêm mới"
            onClick={() => {
              setAddDialogVisible(true);
            }}
            style={{ height: "35px" }}
            disabled={!selectArrayPermisstion?.includes("CREATE_USER")}
          />
          <IconField
            iconPosition="left"
            className="search-item flex items-center w-8"
          >
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm"
              className="border-1"
              style={{
                height: "35px",
              }}
              value={inputSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputSearch(e.target.value)
              }
            />
          </IconField>
        </div>
      </div>
      <ModalAddWarehouse
        toast={toast}
        visible={addDialogVisible}
        onHide={() => setAddDialogVisible(false)}
      />
      <div className="bg-white border-round-md shadow-1 p-3">
        <div className="flex justify-content-between align-items-center ">
          <TabMenu
            model={listMenu.map((item, index) => ({
              ...item,
              template: () =>
                tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
            }))}
            activeIndex={tabMenuActive}
            onTabChange={(e) => setTabmenuActive(e.index)}
          />
        </div>

        {warehouseData && warehouseData.data ? (
          <>
            <DataTable
              value={dataTable}
              emptyMessage="Không có dữ liệu"
              size="small"
              className="custom-table"
              scrollable
              scrollHeight="calc(100vh - 20rem)"
              style={{ height: "calc(100vh - 20rem)" }}
            >
              <Column
                header="STT"
                body={(rowData, options) => (
                  <div className="text-center">
                    {first + options.rowIndex + 1}
                  </div>
                )}
                style={{ width: "5%" }}
                alignHeader={"center"}
                align={"center"}
              />
              <Column header="Tên kho" field="name" body={warehouseTemplate} />
              <Column header="Mã kho" field="code" />
              <Column
                header="Địa chỉ"
                field="address"
                style={{ minWidth: "100px" }}
              />
              <Column header="Khu vực" field="area" body={areaBodyTemplate} />
              <Column header="Ghi chú" field="description" />
              <Column body={actionBodyTeamplate} />
            </DataTable>
            <Paginator
              first={first}
              rows={rows}
              totalRecords={warehouseData?.total}
              rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa kho hàng này?
          </div>
        }
      />
      <ModalEditWarehouse
        visible={modalEdit}
        onHide={() => setModalEdit(false)}
        toast={toast}
        rowData={rowData}
      />
    </div>
  );
}

export default Warehouse;
