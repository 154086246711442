export const formatCurrency = (value: number) => {
  if (value === null || value === undefined) return "";
  const number = Math.abs(value);
  const formatted = number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return value < 0 ? `-${formatted}` : formatted;
};

export const formatDate = (date: Date | null): string => {
  if (!date) return "";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateTime = (dateString: string | null): string => {
  // Nếu dateString là null hoặc không thể chuyển thành đối tượng Date hợp lệ, trả về chuỗi rỗng
  if (!dateString) return "";

  const date = new Date(dateString);

  // Kiểm tra xem date có phải là một đối tượng Date hợp lệ hay không
  if (isNaN(date.getTime())) return "";

  // Lấy các phần tử của ngày, tháng, năm, giờ, phút, giây
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0 nên cần +1
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Trả về chuỗi đã được định dạng theo kiểu dd/MM/yyyy HH:mm:ss
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const formatDatePDF = (dateString: string | null): string => {
  // Nếu dateString là null hoặc không thể chuyển thành đối tượng Date hợp lệ, trả về chuỗi rỗng
  if (!dateString) return "";

  const date = new Date(dateString);

  // Kiểm tra xem date có phải là một đối tượng Date hợp lệ hay không
  if (isNaN(date.getTime())) return "";

  // Lấy các phần tử của ngày, tháng, năm, giờ, phút, giây
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0 nên cần +1
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}/${month}/${year}`;
};

export const formatDateUser = (date: Date | null): string => {
  if (!date) return "";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export function changeDateFormat(dateString?: string): string {
  if (typeof dateString !== "string") {
    return "";
  }

  const date = new Date(dateString);

  // Kiểm tra nếu đối tượng Date không hợp lệ
  if (isNaN(date.getTime())) {
    return "";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}/${month}/${year}`;
}
