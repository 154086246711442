import React from "react";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";

interface PrivateLayoutProps {
  children: React.ReactNode;
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
  return (
    <div className="h-screen w-screen flex flex-row surface-100 h-100vh">
      <div className="hidden xl:flex bg-white">
        <SidebarMenu />
      </div>
      <div className="flex flex-column h-screen overflow-hidden flex-auto">
        <div>
          <Header />
        </div>
        {/* <div className="p-2 md:p-3 overflow-auto">{children}</div> */}
        <div
          className="flex-grow overflow-auto"
          style={{ height: "calc(100% - 4rem)" }}
        >
          <div className="p-2 md:p-3">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
