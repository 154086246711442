import React from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ConfirmPopup } from "primereact/confirmpopup";

interface ModelDeleteProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  accept: () => void;
  msg: any;
}
const ModelDelete: React.FC<ModelDeleteProps> = ({
  visible,
  setVisible,
  accept,
  msg = "Bạn chắc chắn muốn xóa mục này?",
}) => {
  return (
    <>
      {/* <ConfirmDialog
        visible={visible}
        content={() => (
          <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
              <i className="pi pi-exclamation-triangle text-5xl"></i>
            </div>
            <span className="font-bold text-2xl block mb-2 mt-4">Cảnh báo</span>
            <div className="mb-0">{msg}</div>
            <div className="flex align-items-center gap-2 mt-4">
              <Button
                label="Hủy"
                outlined
                onClick={() => setVisible(false)}
                className="w-8rem"
              ></Button>
              <Button
                label="Đồng ý"
                onClick={accept}
                className="w-8rem"
              ></Button>
            </div>
          </div>
        )}
      /> */}
      <ConfirmPopup
        visible={visible}
        onHide={() => setVisible(false)}
        message={<strong>{msg}</strong>}
        icon="pi pi-exclamation-triangle text-3xl custom-icon"
        accept={accept}
        reject={() => setVisible(false)}
        acceptLabel="Đồng ý"
        rejectLabel="Hủy"
        acceptClassName="p-button-danger"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </>
  );
};

export default ModelDelete;
